import Vue       from 'vue'
import VueRouter from 'vue-router'
import store     from '../store'

import Home                                         from '../views/Home.vue'
import NotFound                                     from '../views/404.vue'
import Unauthorized_401                             from '../views/401.vue'
import Unauthorized                                 from '../views/Unauthorized.vue'
import Expired                                      from '../views/Expired.vue'
import user_appointments_index                      from '../views/user/appointments/index.vue'
import user_appointments_create                     from '../views/user/appointments/create.vue'
import user_appointments_therapy_create             from '../views/user/appointments/create_therapy.vue'
import user_appointments_therapy_update             from '../views/user/appointments/update.vue'
import user_appointments_blood_test_create          from '../views/user/appointments/create_blood_test.vue'
import user_appointments_blood_test_update          from '../views/user/appointments/update.vue'
import user_appointments_update                     from '../views/user/appointments/update.vue'
import user_rooms_index                             from '../views/user/rooms/index.vue'
import user_rooms_create                            from '../views/user/rooms/create.vue'
import user_rooms_update                            from '../views/user/rooms/update.vue'
import user_chairs_index                            from '../views/user/chairs/index.vue'
import user_chairs_create                           from '../views/user/chairs/create.vue'
import user_chairs_update                           from '../views/user/chairs/update.vue'
import user_chairs_duration_index                   from '../views/user/chairs/duration/index.vue'
import user_chairs_duration_create                  from '../views/user/chairs/duration/create.vue'
import user_chairs_duration_update                  from '../views/user/chairs/duration/update.vue'
import user_blood_test_type_index                   from '../views/user/blood_test/index.vue'
import user_blood_test_type_create                  from '../views/user/blood_test/create.vue'
import user_blood_test_type_update                  from '../views/user/blood_test/update.vue'
import user_pathologies_index                       from '../views/user/pathologies/index.vue'
import user_pathologies_create                      from '../views/user/pathologies/create.vue'
import user_pathologies_update                      from '../views/user/pathologies/update.vue'
import user_therapies_index                         from '../views/user/therapies/index.vue'
import user_therapies_create                        from '../views/user/therapies/create.vue'
import user_therapies_update                        from '../views/user/therapies/update.vue'
import user_pathologies_therapies_create            from '../views/user/pathologies_therapies/create.vue'
import user_pathologies_therapies_update            from '../views/user/pathologies_therapies/update.vue'
import user_patients_index                          from '../views/user/patients/index.vue'
import user_patients_create                         from '../views/user/patients/create.vue'
import user_patients_update                         from '../views/user/patients/update.vue'
import user_patients_pathologies_create             from '../views/user/patients_pathologies/create.vue'
import user_patients_pathologies_update             from '../views/user/patients_pathologies/update.vue'
import user_users_index                             from '../views/user/users/index.vue'
import user_users_create                            from '../views/user/users/create.vue'
import user_users_update                            from '../views/user/users/update.vue'
import user_user_types_index                        from '../views/user/user_types/index.vue'
import user_user_types_create                       from '../views/user/user_types/create.vue'
import user_user_types_update                       from '../views/user/user_types/update.vue'
import password_create                              from '../views/password/create.vue'
import password_update                              from '../views/password/update.vue'
import user_logs_index                              from '../views/user/logs/index.vue'
import user_users_software_functions_create         from '../views/user/users_software_functions/create.vue'
import user_users_software_functions_update         from '../views/user/users_software_functions/update.vue'
import user_users_software_special_functions_create from '../views/user/user_types_software_special_functions/create.vue'
import user_users_software_special_functions_update from '../views/user/user_types_software_special_functions/update.vue'
import user_therapy_program_create                  from '../views/user/therapy_program/create.vue'
import user_therapy_program_update                  from '../views/user/therapy_program/update.vue'
import user_visit_types_index                       from '../views/user/visit_types/index.vue'
import user_visit_types_create                      from '../views/user/visit_types/create.vue'
import user_visit_types_update                      from '../views/user/visit_types/update.vue'

// Statistiche
import user_analytics_index        from '../views/user/analytics/index.vue'
import user_analytics_appointments from '../views/user/analytics/appointments.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:     `/`,
    name:     `home`,
    component: Home,
    meta: {
      requiresGuest: true
    }
  },
  {
    path:     `/user/agenda`,
    name:     `user_appointments_index`,
    component: user_appointments_index,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  },
  {
    path:     `/user/agenda/aggiungi`,
    name:     `user_appointments_create`,
    component: user_appointments_create,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  },
  {
    path:     `/user/agenda/aggiungi_terapia`,
    name:     `user_appointments_therapy_create`,
    component: user_appointments_therapy_create,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  },
  {
    path:     `/user/agenda/aggiungi_prelievo`,
    name:     `user_appointments_blood_test_create`,
    component: user_appointments_blood_test_create,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  }, 
  {
    path:     `/user/agenda/modifica/:id`,
    name:     `user_appointments_update`,
    component: user_appointments_update,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  },
  {
    path:     `/user/agenda/modifica/:id/:id_therapy`,
    name:     `user_appointments_therapy_update`,
    component: user_appointments_therapy_update,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  },
  {
    path:     `/user/agenda/modifica/:id/:id_blood_test`,
    name:     `user_appointments_blood_test_update`,
    component: user_appointments_blood_test_update,
    meta: {
      requiresAuth: true,
      title:       `Agenda`
    }
  },   
  {
    path:     `/user/stanze`,
    name:     `user_rooms_index`,
    component: user_rooms_index,
    meta: {
      requiresAuth: true,
      title:       `Stanze`
    }
  },
  {
    path:     `/user/stanze/aggiungi`,
    name:     `user_rooms_create`,
    component: user_rooms_create,
    meta: {
      requiresAuth: true,
      title:       `Stanze`
    }
  },
  {
    path:     `/user/stanze/modifica/:title`,
    name:     `user_rooms_update`,
    component: user_rooms_update,
    meta: {
      requiresAuth: true,
      title:       `Stanze`
    }
  },
  {
    path:     `/user/poltrone`,
    name:     `user_chairs_index`,
    component: user_chairs_index,
    meta: {
      requiresAuth: true,
      title:       `Poltrone`
    }
  },
  {
    path:     `/user/poltrone/aggiungi`,
    name:     `user_chairs_create`,
    component: user_chairs_create,
    meta: {
      requiresAuth: true,
      title:       `Poltrone`
    }
  },
  {
    path:     `/user/poltrone/modifica/:title/:number`,
    name:     `user_chairs_update`,
    component: user_chairs_update,
    meta: {
      requiresAuth: true,
      title:       `Poltrone`
    }
  }, 
  {
    path:     `/user/poltrone/duration`,
    name:     `user_chairs_duration_index`,
    component: user_chairs_duration_index,
    meta: {
      requiresAuth: true,
      title:       `Poltrone`
    }
  },
  {
    path:     `/user/poltrone/duration/aggiungi`,
    name:     `user_chairs_duration_create`,
    component: user_chairs_duration_create,
    meta: {
      requiresAuth: true,
      title:       `Poltrone`
    }
  },
  {
    path:     `/user/poltrone/duration/modifica/:title`,
    name:     `user_chairs_duration_update`,
    component: user_chairs_duration_update,
    meta: {
      requiresAuth: true,
      title:       `Poltrone`
    }
  },  
  {
    path:     `/user/tipo_prelievo`,
    name:     `user_blood_test_type_index`,
    component: user_blood_test_type_index,
    meta: {
      requiresAuth: true,
      title:       `Tipo prelievo`
    }
  },
  {
    path:     `/user/tipo_prelievo/aggiungi`,
    name:     `user_blood_test_type_create`,
    component: user_blood_test_type_create,
    meta: {
      requiresAuth: true,
      title:       `Tipo prelievo`
    }
  },
  {
    path:     `/user/tipo_prelievo/modifica/:title`,
    name:     `user_blood_test_type_update`,
    component: user_blood_test_type_update,
    meta: {
      requiresAuth: true,
      title:       `Tipo prelievo`
    }
  },         
  {
    path:     `/user/tipo_visita`,
    name:     `user_visit_types_index`,
    component: user_visit_types_index,
    meta: {
      requiresAuth: true,
      title:       `Tipo visite`
    }
  },
  {
    path:     `/user/tipo_visita/aggiungi`,
    name:     `user_visit_types_create`,
    component: user_visit_types_create,
    meta: {
      requiresAuth: true,
      title:       `Tipo visite`
    }
  },
  {
    path:     `/user/tipo_visita/modifica/:title`,
    name:     `user_visit_types_update`,
    component: user_visit_types_update,
    meta: {
      requiresAuth: true,
      title:       `Tipo visite`
    }
  },  
  {
    path:     `/user/patologie`,
    name:     `user_pathologies_index`,
    component: user_pathologies_index,
    meta: {
      requiresAuth: true,
      title:       `Patologie`
    }
  },
  {
    path:     `/user/patologie/aggiungi`,
    name:     `user_pathologies_create`,
    component: user_pathologies_create,
    meta: {
      requiresAuth: true,
      title:       `Patologie`
    }
  },
  {
    path:     `/user/patologie/modifica/:title`,
    name:     `user_pathologies_update`,
    component: user_pathologies_update,
    meta: {
      requiresAuth: true,
      title:       `Patologie`
    }
  },
  {
    path:     `/user/patologie/modifica/:title/associazione/terapie/aggiungi`,
    name:     `user_pathologies_therapies_create`,
    component: user_pathologies_therapies_create,
    meta: {
      requiresAuth: true,
      title:       `Patologie`
    }
  },
  {
    path:     `/user/patologie/modifica/:title/associazione/terapie/modifica/:id`,
    name:     `user_pathologies_therapies_update`,
    component: user_pathologies_therapies_update,
    meta: {
      requiresAuth: true,
      title:       `Patologie`
    }
  },  
  {
    path:     `/user/terapie`,
    name:     `user_therapies_index`,
    component: user_therapies_index,
    meta: {
      requiresAuth: true,
      title:       `Terapie`
    }
  },
  {
    path:     `/user/terapie/aggiungi`,
    name:     `user_therapies_create`,
    component: user_therapies_create,
    meta: {
      requiresAuth: true,
      title:       `Terapie`
    }
  },
  {
    path:     `/user/terapie/modifica/:title`,
    name:     `user_therapies_update`,
    component: user_therapies_update,
    meta: {
      requiresAuth: true,
      title:       `Terapie`
    }
  },
  {
    path:     `/user/pazienti`,
    name:     `user_patients_index`,
    component: user_patients_index,
    meta: {
      requiresAuth: true,
      title:       `Pazienti`
    }
  },
  {
    path:     `/user/pazienti/aggiungi`,
    name:     `user_patients_create`,
    component: user_patients_create,
    meta: {
      requiresAuth: true,
      title:       `Pazienti`
    }
  },
  {
    path:     `/user/pazienti/modifica/:CF`,
    name:     `user_patients_update`,
    component: user_patients_update,
    meta: {
      requiresAuth: true,
      title:       `Pazienti`
    }
  },
  {
    path:     `/user/pazienti/modifica/:CF/associazione/patologie/aggiungi`,
    name:     `user_patients_pathologies_create`,
    component: user_patients_pathologies_create,
    meta: {
      requiresAuth: true,
      title:       `Associazione paziente terapie`
    }
  },
  {
    path:     `/user/pazienti/modifica/:CF/associazione/patologie/modifica/:id`,
    name:     `user_patients_pathologies_update`,
    component: user_patients_pathologies_update,
    meta: {
      requiresAuth: true,
      title:       `Associazione paziente terapie`
    }
  },
  {
    path:     `/user/utenti`,
    name:     `user_users_index`,
    component: user_users_index,
    meta: {
      requiresAuth: true,
      title:       `Utenze`
    }
  },
  {
    path:     `/user/utenti/aggiungi`,
    name:     `user_users_create`,
    component: user_users_create,
    meta: {
      requiresAuth: true,
      title:       `Utenze`
    }
  },
  {
    path:     `/user/utenti/modifica/:username`,
    name:     `user_users_update`,
    component: user_users_update,
    meta: {
      requiresAuth: true,
      title:       `Utenze`
    }
  },  
  {
    path:     `/user/utenti/tipologie`,
    name:     `user_user_types_index`,
    component: user_user_types_index,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/user/utenti/tipologie/aggiungi`,
    name:     `user_user_types_create`,
    component: user_user_types_create,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/user/utenti/tipologie/modifica/:title`,
    name:     `user_user_types_update`,
    component: user_user_types_update,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/user/utenti/tipologie/modifica/:title/autorizzazioni/aggiungi`,
    name:     `user_users_software_functions_create`,
    component: user_users_software_functions_create,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/user/utenti/tipologie/modifica/:title/autorizzazioni/modifica/:software_function`,
    name:     `user_users_software_functions_update`,
    component: user_users_software_functions_update,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/user/utenti/tipologie/modifica/:title/autorizzazioni_speciali/aggiungi`,
    name:     `user_users_software_special_functions_create`,
    component: user_users_software_special_functions_create,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/user/utenti/tipologie/modifica/:title/autorizzazioni_speciali/modifica/:software_special_function`,
    name:     `user_users_software_special_functions_update`,
    component: user_users_software_special_functions_update,
    meta: {
      requiresAuth: true,
      title:       `Autorizzazioni`
    }
  },
  {
    path:     `/password/crea/:email&:token&:username`,
    name:     `password_create`,
    component: password_create,
    meta: {
      requiresGuest: true
    }
  },
  {
    path:     `/password/modifica/:email&:token&:username`,
    name:     `password_update`,
    component: password_update,
    meta: {
      requiresGuest: true
    }
  },
  {
    path:     `/user/pazienti/modifica/:CF/associazione/patologie/modifica/:id/programma_terapeutico/aggiungi`,
    name:     `user_therapy_program_create`,
    component: user_therapy_program_create,
    meta: {
      requiresAuth: true,
      title:       `Programma terapeutico`
    }
  },
  {
    path:     `/user/pazienti/modifica/:CF/associazione/patologie/modifica/:id_patient_pathology/programma_terapeutico/modifica/:id`,
    name:     `user_therapy_program_update`,
    component: user_therapy_program_update,
    meta: {
      requiresAuth: true,
      title:       `Programma terapeutico`
    }
  },
  {
    path:     `/user/logs`,
    name:     `user_logs_index`,
    component: user_logs_index,
    meta: {
      requiresAuth: true,
      title:       `Logs`
    }
  },

  // Statistiche
  {
    path:     `/user/statistiche`,
    name:     `user_analytics_index`,
    component: user_analytics_index,
    meta: {
      requiresAuth: true,
      title:       `Statistiche`
    }
  },
  {
    path:     `/user/statistiche/appuntamenti`,
    name:     `user_analytics_appointments`,
    component: user_analytics_appointments,
    meta: {
      requiresAuth: true,
      title:       `Statistiche`
    }
  },

  {
    path:     `/401`,
    name:     `Unauthorized_401`,
    component: Unauthorized_401
  },
  {
    path:     `/nonautorizzato`,
    name:     `Unauthorized`,
    component: Unauthorized
  },
  {
    path:     `/sessione_scaduta`,
    name:     `Expired`,
    component: Expired
  },
  {
    path:     `/:catchAll(.*)`,
    name:     `404`,
    component: NotFound
  }
]

const router = new VueRouter({
  mode: `history`,
  base: process.env.BASE_URL,
  routes
})


const pageAuthorized = (title) => {
  console.log(title);
  for(let softwareFunction of store.getters.softwareFunctions) {
    if(softwareFunction.software_function.title == title && softwareFunction.abilitation) {
      return true
    }
  }

  return false

}

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters.isLogged) {
      next({
        name: `home`
      })
    }
    else if(!store.getters.isAuthorized || store.getters.isBlocked) {
      next({
        name: `Unauthorized`
      })
    }    
    else if(!pageAuthorized(to.meta.title)) {
      next({
        name: 'Unauthorized_401'
      })
    }
    else {
      next()
    }

  }
  else if(to.matched.some(record => record.meta.requiresGuest)) {

    if(store.getters.isLogged) {
      next({
        name: `user_appointments_index`
      })
    }
    else {
      next()
    }
    
  }
  else {
    next()
  }

})

export default router