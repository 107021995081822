<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div v-show="rooms.DB.length < 1" class="alert alert-warning m-0">
                        Ancora nessuna stanza
                    </div>

                    <div class="row">
                        <div v-for="room of rooms.DB" :key="room.id" div class="col-lg-4">
                            <div class="card card-body mb-2">
                                <div class="row">
                                    <div class="col-md-7">
                                        <p class="text-uppercase font-weight-bold m-0">
                                            {{room.title}}
                                        </p>
                                        <p>
                                            <small>
                                                {{room.description ? room.description : `Nessuna descrizione`}}
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-md-5 text-right d-flex flex-column justify-content-between">

                                        <div v-if="room.state" class="badge badge-success mb-1">Visibile</div>
                                        <div v-else class="badge badge-warning mb-1">Non visibile</div>

                                        <router-link tag="button" :to="`/user/stanze/modifica/${room.title}`" class="btn btn-ifo btn-sm" :disabled="functionDisabled(2, 'read')">
                                            Gestisci
                                        </router-link>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <router-link class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" to="/user/stanze/aggiungi" v-bind:class="{'disabled': functionDisabled(2, 'create')}">
                            <span class="d-none d-md-block">Aggiungi stanza</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import store  from '@/store'

    export default {
        data() {
            return {
                rooms: {
                    DB: []
                },
                loaders: {
                    reading: true
                }
            }
        },
        methods: {
            async readRooms() {

                try {

                    const rooms = await this.axios.get(`user/rooms/read/all`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.rooms.DB = rooms.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading = false
                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readRooms()
        }
    }

</script>