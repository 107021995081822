<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-md-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <p class="font-weight-bold m-0">Paziente</p>

                    <p class="text-muted mb-3">
                        <small>
                           Ricerca il paziente compilando uno dei 3 campi che seguono
                        </small>
                    </p>

                    <form @submit.prevent="createBloodTest" autocomplete="off">

                        <div class="row">
                            
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="surname">Cognome</label>
                                    <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.user.surname.$model" v-bind:class="{'is-invalid': (!$v.form.user.surname.minLength && $v.form.user.surname.$dirty && !validation.ok_search_patient) || validation.ko_search_patient, 'is-valid': validation.ok_search_patient}" @input="read_by_filter_new">

                                    <p v-if="!$v.form.user.surname.minLength && $v.form.user.surname.$dirty && !validation.ok_search_patient" class="m-0 text-danger">
                                        <small>Minimo 3 caratteri</small>
                                    </p>
                                    <p v-if="validation.ko_search_patient" class="m-0 text-danger">
                                        <small>{{validation.ko_search_patient}}</small>
                                    </p>
                                    <p v-if="validation.ok_search_patient" class="m-0 text-success">
                                        <small>{{validation.ok_search_patient}}</small>
                                    </p>

                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.user.name.$model" v-bind:class="{'is-invalid': (!$v.form.user.name.minLength && $v.form.user.name.$dirty && !validation.ok_search_patient) || validation.ko_search_patient, 'is-valid': validation.ok_search_patient}" @input="read_by_filter_new">
                                    
                                    <p v-if="!$v.form.user.name.minLength && $v.form.user.name.$dirty && !validation.ok_search_patient" class="m-0 text-danger">
                                        <small>Minimo 3 caratteri</small>
                                    </p>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="CF">Codice fiscale</label>
                                    <input type="text" class="form-control text-uppercase f-family-monospace font-weight-bold" id="CF" v-model.trim="$v.form.user.CF.$model" v-bind:class="{'is-invalid': (!$v.form.user.CF.minLength && $v.form.user.CF.$dirty && !validation.ok_search_patient) || validation.ko_search_patient, 'is-valid': validation.ok_search_patient}" @input="read_by_filter_new">

                                    <p v-if="!$v.form.user.CF.minLength && $v.form.user.CF.$dirty && !validation.ok_search_patient" class="m-0 text-danger">
                                        <small>Minimo 3 caratteri</small>
                                    </p>

                                </div>
                            </div>
                            
                        </div>

                        <p class="m-0" v-show="loaders.reading.patients">
                            <small class="text-muted">
                                Ricerca pazienti..
                            </small>
                        </p>

                        <div v-if="patients.length > 0" class="table-responsive">
                            <table class="table_AO">
                                <thead>
                                    <tr>
                                        <th>
                                            Cognome
                                        </th>
                                        <th>
                                            Nome
                                        </th>
                                        <th>
                                            Codice fiscale
                                        </th>
                                        <th class="text-center">
                                            Patologie
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="patient of patients" :key="`patient_${patient.id}`" @click="update_form_user_new(patient)">
                                        <td>
                                            {{patient.surname}}
                                        </td>
                                        <td>
                                            {{patient.name}}
                                        </td>
                                        <td class="f-family-monospace font-weight-bold">
                                            {{patient.CF}}
                                        </td>
                                        <td class="f-family-monospace text-center font-weight-bold">
                                            {{patient.patients_pathologies.length}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Campo note paziente -->
                        <div class="form-group mt-2" v-if="patients_pathologies.length > 0">
                            <label for="note">Note</label>
                            <textarea type="text" class="form-control" id="surnotename" :value="form.user.note" placeholder="Note sul paziente ricercato.." disabled></textarea>
                        </div>


                        <div class="row">
                            <div class="col-lg-6 border-right-lg">

                                <div v-if="patients_pathologies.length > 0">

                                    <p class="font-weight-bold m-0">Informazioni prelievo</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Riepilogo sulle informazioni della visita
                                        </small>
                                    </p>
                                    <div class="form-group">

                                        <label for="pathologies">Seleziona una patologia</label>

                                        <select class="form-control" id="pathologies" v-model="form.appointment.id_patient_pathology" @input="update_therapies($event.target.value)">
                                            <option value="" selected disabled>
                                                Seleziona una patologia
                                            </option>
                                            <option v-for="patient_pathology of patients_pathologies" :key="`pathology_${patient_pathology.id}`" :value="patient_pathology.id">
                                                {{patient_pathology.pathology.title}}
                                            </option>
                                        </select>

                                        <small class="form-text text-muted">
                                            Seleziona la patologia per l'appuntamento
                                        </small>

                                    </div>

                                    <div class="form-group" v-show="form.appointment.id_patient_pathology">

                                        <label for="id_blood_test_type">Seleziona tipo prelievo</label>

                                        <select class="form-control mb-2" id="id_blood_test_type" @change="onChangeBloodTestType($event.target.value)" v-model="DB.blood_test_type">
                                            <option value="0">Seleziona</option>
                                            <option v-for="blood_test_type of DB.blood_test_types" :key="`bloodtesttypes_${blood_test_type.id}`" :value="blood_test_type.id">
                                                {{blood_test_type.title}}
                                            </option>
                                        </select> 

                                        <p id="visit_type_help" class="form-text text-muted mb-0">
                                            <small>
                                                Seleziona il tipo di prelievo
                                            </small>
                                        </p>

                                    </div>

                                </div>
                                <div v-show="DB.blood_test_type > 0">

                                    <div class="form-group" v-if="this.functionSpecialEnabled(4)" v-show="form.appointment.id_patient_pathology">

                                        <div class="custom-control custom-checkbox text-truncate">
                                            <input type="checkbox" class="custom-control-input" id="urgent" v-model="form.appointment.urgent" @change="set_params_for_appointment_urgent()">
                                            <label class="custom-control-label font-weight-bold text-danger text-underline" for="urgent"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-exclamation-octagon mr-1" viewBox="0 0 16 16">
                                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                            </svg> <u>Prelievo urgente</u></label>
                                        </div>

                                        <small class="form-text text-muted">
                                            Hai la possibilità di creare un prelievo urgente impostando <b>data</b> ed <b>orario</b> in <b>questo momento</b>! Non verrano prese in considerazione le notifiche per il paziente.
                                        </small>

                                    </div>                                   

                                    <button class="btn btn-ifo" :disabled="loaders.creating" v-if="form.appointment.urgent">

                                        <span v-show="!loaders.creating">
                                            Aggiungi
                                        </span>

                                        <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>                                    
                                </div>

                            </div>
                            <div class="col-lg-6" v-show="patients_pathologies.length > 0">
                                
                                <p class="font-weight-bold m-0">Patologie associate al paziente</p>
                                <div class="container-fluid p-3">
                                    
                                    <div v-if="patients_pathologies.length < 1" class="alert alert-warning m-0">
                                        Ancora nessuna patologia associata
                                    </div>

                                    <div v-else class="row">
                                        <div v-for="patient_pathology of patients_pathologies" :key="patient_pathology.id" div class="col-lg-12">
                                            <div class="card card-tab card-body mb-2">
                                                <p class="text-uppercase m-0 font-weight-bold">
                                                    Patologia
                                                </p>
                                                <p class="text-truncate">
                                                    {{patient_pathology.pathology.title}}
                                                </p>
                                                <p class="text-uppercase m-0 font-weight-bold">
                                                    Terapia
                                                </p>
                                                <p class="text-truncate">
                                                    {{patient_pathology.id_therapy ? patient_pathology.therapy.title : "Nessuna terapia"}}
                                                </p>
                                                <p class="text-uppercase m-0 font-weight-bold text-truncate">
                                                    Durata appuntamento
                                                </p>
                                                <p class="text-truncate">
                                                    {{patient_pathology.sessions_duration}} minuti
                                                </p>
                                                <p class="text-uppercase m-0 font-weight-bold text-truncate">
                                                    Appuntamenti creati in precedenza
                                                </p>
                                                <p class="m-0 text-truncate" v-if="patient_pathology.appointment.length > 0">
                                                    {{patient_pathology.appointment.length}} appuntamenti
                                                </p>
                                                <p class="m-0 text-truncate text-uppercase text-success" v-else>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-bookmark-star" viewBox="0 0 16 16">
                                                        <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z"/>
                                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                                    </svg>
                                                    
                                                    <strong>
                                                        Primo appuntamento
                                                    </strong>
                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
 

                            </div>
                        </div>

                        <!-- Se è stata selezionata la stanza e l'appuntamento non è urgente -->
                        <div v-if="DB.blood_test_type > 0 && !form.appointment.urgent">

                            <div class="alert alert-warning" v-show="validation.conflict">
                                {{validation.conflict}}
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="form-group">

                                        <label for="date_appointment">Data</label>

                                        <input type="date" class="form-control" id="date_blood_test" v-model="form.appointment.blood_test_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_blood_test">
                                        
                                        <small class="form-text text-muted"></small>
                                        
                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_test_date.required && $v.form.appointment.blood_test_date.$dirty">
                                            Data terapia richiesta
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_test_date.minValue && $v.form.appointment.blood_test_date.$dirty">
                                            La data deve essere impostata da domani
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">

                                        <label for="appointment_h_start">Orario inizio</label>

                                        <input type="time" class="form-control" id="blood_test_h_start" v-model="form.appointment.blood_test_time" v-bind:class="{'is-invalid': !$v.form.appointment.blood_test_time.required && $v.form.appointment.blood_test_time.$dirty}">
                                        
                                        <small class="form-text text-muted"></small>

                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_test_time.required && $v.form.appointment.blood_test_time.$dirty">
                                            Orario inizio richiesto
                                        </div>
                                        
                                    </div>
                                </div>
                                <!-- Campo note -->
                                <div class="col-lg-12 mb-3">
                                    <label for="note">Note prelievo</label>
                                    <textarea type="text" class="form-control" rows="5" id="note_create" v-model="form.appointment.note_create" placeholder="Note prelievo"></textarea>
                                </div>                                  
                            </div>

                            <div class="row">
                                <div class="col-lg-6">                           
                                    <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Puoi avvisare il paziente tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                        </small>
                                    </p>

                                    <div class="row" v-if="req_activation_therapy_program">
                                        <div class="col-lg-8">
                                            <div class="alert alert-danger">
                                                Con il programma teraupetico attivo, il paziente verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" v-model="notify.phone" :disabled="!form.user.phone">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                            <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email" :disabled="!form.user.email">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">                           
                                    <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Puoi avvisare il caregiver tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                        </small>
                                    </p>

                                    <div class="row" v-if="req_activation_therapy_program">
                                        <div class="col-lg-8">
                                            <div class="alert alert-danger">
                                                Con il programma teraupetico attivo, il caregiver verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" v-model="notify.phonecg" :disabled="!form.user.phonecg">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                            <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg" :disabled="!form.user.emailcg">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                        </div>
                                    </div>
                                </div>                                
                            </div>       


                            <div v-if="validation.ko != 'Prelievo eliminato con successo!'">
                                <div v-show="validation.ko" class="alert alert-danger text-center">
                                    {{validation.ko}}
                                </div>
                            </div>
 
                            <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating || disabled == 1">
                                    
                                <span v-show="!loaders.creating">
                                    Aggiungi
                                </span>

                                <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                            </button>

                        </div>
                    </form>
                </div>
                
            </div>
            
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column d-none d-md-block">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength
    } from "vuelidate/lib/validators"

    //import DoughnutChart from './DoughnutChart.js'
    //import VueCal        from 'vue-cal'
    import moment        from 'moment'
    import store         from '@/store'

    //import 'vue-cal/dist/i18n/it.js'
    //import 'vue-cal/dist/vuecal.css'


    export default {
        components: {
            //DoughnutChart,
            //VueCal
        },
        data() {
            return {  
                stickySplitLabels: false,
                minCellWidth: 100,
                minSplitWidth: 100,   
                minDate: moment().add(1, "days").format("YYYY-MM-DD"),
                maxDate:      null,
                timeFrom:     7*60,
                timeTo:       20*60,
                timeStep:     30,             
                splitDays: [],
                events: [],               
                yearCal: new Date().getFullYear(), 
                classCal: 1, 
                DB: {
                    blood_test_types: [],
                    blood_test_type: 0
                },                        
                patients: [

                ],
                pathologies: {
                    DB: []
                },                                      
                appointments: {
                    DB: []
                },
                appointments_patient: {
                    DB: []
                },                
                form: {
                    user: {
                        CF:         "",
                        name:       "",
                        surname:    "",
                        email:      null,
                        phone:      null,
                        emailcg:    null,
                        phonecg:    null,                        
                        note:       null
                    },
                    appointment: {
                        date:                 ``,
                        h_start:              ``,
                        h_end:                ``,
                        id_room:              null,
                        id_visit_type:        0,
                        id_patient_pathology: null,
                        urgent:               false,
                        deleteTherapy:        0,
                        outside:              2,
                        note_create:          null,
                        blood_test: {
                            id: "",
                            date: "",
                            h_start: ""
                        }, 
                        blood_test_date:     "",
                        blood_test_time:     ""                                                        
                    },
                    period:       null,
                    room:         null,
                    visit_type:   0,
                    patients_pathologies: {
                        id_therapy: null,
                        id:         null
                    },
                    form_edit_therapy: {
                        id:                   0,
                        description:          null,
                        id_patient_pathology: null,
                        id_therapy:           null,
                        title_old_therapy:    null,
                        therapy_on_select:    null,
                        modifier_user:        this.$store.state.auth.username
                    }
                },
                search: {
                    names:    [],
                    surnames: [],
                    CFs:      []
                },
                validation: {
                    ko: false,
                    ko_update_therapy: false,
                    ok_update_therapy: false,
                    ko_search_patient: false,
                    ok_search_patient: false,
                    conflict:          false
                },
                loaders: {
                    creating: false,
                    reading: {
                        therapy:  false,
                        patients: false,
                        rooms: false
                    },
                    update_therapy: false,
                    delete_therapy: false
                },

                chart: {
                    options: {
                        legend: {
                            display: false
                        }
                    },
                    dataCollection: {
                        labels: [
                            'Appuntamenti effettuati',
                            'Appuntamenti da effettuare'
                        ],
                        datasets: [
                            {
                                data: [0, 0],
                                backgroundColor: [
                                    '#5c1b24',
                                    '#343a40'
                                ]
                            }
                        ]
                    },
                    myLegend: {
                        effettuate:   0,
                        daEffettuare: 0
                    },
                    update: 0
                    
                },
                patients_pathologies:[],
                therapy: null,
                calendar: {
                    config: {
                        minDate:      moment().add(1, "days").format("YYYY-MM-DD"),
                        maxDate:      null,
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        timeStep:     20,
                        roomSelected: {},
                        events:       [],
                        hideWeekDays: [6,7],
                        selectedDate: null,
                        specialHours: []  
                    }
                },
                therapy_program: null,
                req_activation_therapy_program: false,
                notify: {
                    phone: false,       
                    email: false,
                    phonecg: false,
                    emailcg: false
                }                
            }
        },
        validations() {
            return {                     
                disabled: 0, //bottone disabilitato oppure no
                form: {
                    user: {
                        CF: {
                            minLength: minLength(3),
                        },
                        name: {
                            minLength: minLength(2),
                        },
                        surname: {
                            minLength: minLength(2),
                        }
                    },
                    appointment: {
                        blood_test_date: {
                            required,
                            minValue: value => value > moment().format("YYYY-MM-DD")
                        },
                        blood_test_time: {
                            required
                        }
                    },
                    form_edit_therapy: {
                        description: {
                            maxLength: maxLength(700)
                        }
                    }
                }
            }
        },
        methods: {
            onChangeBloodTestType(event) {
                this.DB.blood_test_type = event    
            },             
            async read_blood_test_types() {
                try {

                   const blood_test_types = (
                        await this.axios.get(`user/blood_test_type/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.DB.blood_test_types = blood_test_types;
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  
            async read_duration() {
                try {

                    this.duration = (
                        await this.axios.get(`user/chairs_duration/read/detailId/${this.DB.selected_duration}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },                
          
            controlla_data() {

                // Controlliamo se la data inserita è minore o uguale ad oggi


                const data_inserita = this.$refs.data_appointment.value

                var date = new Date(data_inserita);

                const day = date.getDay();            

                if(data_inserita == '' || !data_inserita || moment(data_inserita).isSameOrBefore(moment().format("YYYY-MM-DD"))) {
                    
                    this.$toast.open({
                        message: "La data inserita deve partire da domani",
                        type: "error"
                    })
                    this.loaders.creating = true  

                    this.$refs.data_appointment.value = this.form.appointment.date
                } else if(day == 6 || day == 0){
                    this.$toast.open({
                        message: "Non è consentito inserire un appuntamento di sabato o di domenica!",
                        type: "error"
                    })   
                    this.loaders.creating = true                
                } else {
                    this.form.appointment.date = this.$refs.data_appointment.value
                    this.loaders.creating = false 
                }

            },            

            updateYear(){
                var datefull = document.querySelector(".vuecal__title button");
                datefull = datefull.innerHTML;
                var lastChars = datefull.substr(datefull.length - 5);
                var year = lastChars.replace(')', '');
                this.yearCal = year;
            },
                    
            async createBloodTest() {

                this.loaders.creating = true
                this.validation.ko    = false

                // Costruzione oggetto da inviare all'API
                const OBJ = {
                    form: this.form,
                    id_blood_test_type: this.DB.blood_test_type,
                    notify: {
                        email: this.notify.email,
                        phone: this.notify.phone,
                        emailcg: this.notify.emailcg,
                        phonecg: this.notify.phonecg                     
                    }
                }

                try {
                    const res = await this.axios.post(`user/appointments/create_blood_test`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.go(-1)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async readByFilter(keyOfSreach, val, where, orderBy) {

                // Solo pazienti visibili
                where.state = true

                if(val.length >= 3) {
                    try {

                        const res = await this.axios.post(`user/patients/read/search`, {where, orderBy}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.search[keyOfSreach] = res.data

                    }
                    catch(e) {

                        this.search[keyOfSreach] = []

                        this.$toast.open({
                            message: e.response.data.msg,
                            type:   `error`
                        })

                    }
                }
                else {
                    this.search[keyOfSreach] = []
                }

            },
            async read_by_filter_new() {
                const {
                    name,
                    surname,
                    CF
                } = this.form.user

                this.validation.ko_search_patient = false
                this.validation.ok_search_patient = false

                if(name.length >= 3 || surname.length >= 3 || CF.length >= 3) {

                    this.loaders.reading.patients = true

                    try {

                        this.patients = (

                            await this.axios.post(

                                "user/patients/read/search_custom",

                                {
                                    name,
                                    surname,
                                    CF
                                },
                                {
                                    headers:{
                                        Authorization: `bearer ${this.$store.state.auth.token}`
                                    }
                                }

                            )

                        ).data

                        this.validation.ok_search_patient = `Trovati ${this.patients.length} pazienti`

                    }
                    catch(e) {

                        const {
                            message
                        } = e.response.data

                        this.$toast.open({
                            message,
                            type:   `error`
                        })

                        this.validation.ko_search_patient = message
                        this.patients                     = []

                    }
                    finally {
                        this.loaders.reading.patients = false
                    }

                }
                else {

                    // Svuota array
                    if(this.patients.length > 0) this.patients = []

                }

            },
 
            async read_patient_pathology() {

                this.loaders.reading.therapy = true

                try {

                    //console.log("this.form.appointment.id_patient_pathology", this.form.appointment.id_patient_pathology)

                    const {patient_pathology} = (await this.axios.get(`user/patients_pathologies/read_therapy/${this.form.appointment.id_patient_pathology}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })).data

                    if((patient_pathology.therapy && this.form.patients_pathologies.id_therapy == patient_pathology.therapy.id) || (!this.form.patients_pathologies.id_therapy && !patient_pathology.therapy)) {

                        this.$toast.open({
                            message: "Non sono presenti dei cambiamenti per la terapia",
                            type:    "warning"
                        })

                    }
                    else {

                        this.form.patients_pathologies.id_therapy = patient_pathology.therapy ? patient_pathology.therapy.id : null

                        this.$toast.open({
                            message: "Aggiornamento terapia caricato con successo",
                            type:    "success"
                        })
                    }

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading.therapy = false
                }
            },
            async update_therapy() {

                this.loaders.update_therapy       = true
                this.validation.ko_update_therapy = false
                this.validation.ok_update_therapy = false

                try {

                    // Popoliamo l'oggetto
                this.form.form_edit_therapy.id_patient_pathology = this.form.appointment.id_patient_pathology
                this.form.form_edit_therapy.id_therapy           = this.form.patients_pathologies.id_therapy
                this.form.form_edit_therapy.modifier_user        = this.$store.state.auth.username


                    const therapy_updated = await this.axios.post(`user/patients_pathologies/update_therapy`, this.form.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.form_edit_therapy.id = therapy_updated.data.therapy_cronology.id

                    this.form.form_edit_therapy.date = new Date()

                    const OBJ = {...this.form.form_edit_therapy}
                    // Inseriamo ID utile per la computed che recupera la cronologia
                    OBJ.id = therapy_updated.data.therapy_cronology.id
                    
                    // Mostriamo nella tabella la modifica
                    for(const patient_pathology of this.patients_pathologies) {
                        if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                            patient_pathology.therapy_cronologies.push(OBJ)
                        }
                    }                    

                    // Impostiamo la nuova terapia scelta
                    this.form.form_edit_therapy.title_old_therapy = this.form.form_edit_therapy.therapy_on_select
                    this.form.form_edit_therapy.modifier_user = this.$store.state.auth.username

                    const {
                        message
                    } = therapy_updated.data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validation.ok_update_therapy = message

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_update_therapy = message

                }
                finally {
                    this.loaders.update_therapy = false
                }

            },

            async delete_therapy(id_cronology) {

                this.loaders.delete_therapy       = true
                this.validation.ko_delete_therapy = false
                this.validation.ok_delete_therapy = false

                try {
                    this.form.appointment.deleteTherapy = 1
                    this.form.form_edit_therapy.id_therapy = id_cronology

                    const therapy_deleted = await this.axios.post(`user/patients_pathologies/delete_therapy`, this.form.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    for(const patient_pathology of this.patients_pathologies) {
                        if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                            let updateCronologies = patient_pathology.therapy_cronologies.filter((el) => el.id !== id_cronology);
                            patient_pathology.therapy_cronologies = updateCronologies;                                
                        }
                    }                      
                    

                    
                    this.deleteRow = 1  

                    const {
                        message
                    } = therapy_deleted.data


                    this.validation.ok_delete_therapy = message
                    this.form.appointment.deleteTherapy = 0

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_delete_therapy = message
                    this.form.appointment.deleteTherapy = 0

                }
                finally {
                    this.loaders.delete_therapy = false

                }

            },
            async readTherapies() {

                try {

                    this.therapies.DB = (
                        await this.axios.get(`user/therapies/read/actives`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: "Errore di connessione con il server",
                        type: "error"
                    })

                    this.$router.go("/user/agenda")
                }

            },

            updateFormUser(keyOfSreach, values) {

                for(let key in values) {
                    this.form.user[key] = values[key]
                }

                this.search[keyOfSreach]  = []
                this.patients_pathologies = values.patients_pathologies

            },
            async ricerca_asur() {
  
                // Uppercase
                const CF = this.form.user.CF.toUpperCase()

                const {
                    patient
                } = (
                    await this.axios.post(`user/patients/search/asur`, {CF}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                ).data

                /*if(patient.comune == "ROMA"){
                    this.form.appointment.outside = 0
                } else {
                    this.form.appointment.outside = 1
                }*/

                if(patient.decesso != ""){
                    const message = "Paziente deceduto"

                    this.$toast.open({
                        message: message,
                        type: `error`
                    })

                    this.patients_pathologies = []
                    this.validation.ko    = message
                    this.loaders.creating = false
                    return false
                }

            },           
            update_form_user_new(patient) {

                // Prendiamo i parametri passati per valore
                const {
                    patients_pathologies,
                    name,
                    surname,
                    CF,
                    phone,
                    email,
                    phonecg,
                    emailcg
                } = patient

                // Popoliamo i campi input di ricerca
                this.form.user.name    = name
                this.form.user.surname = surname
                this.form.user.CF      = CF
                this.form.user.phone   = phone
                this.form.user.email   = email
                this.form.user.phonecg = phonecg
                this.form.user.emailcg = emailcg             

                this.ricerca_asur()

                if(patients_pathologies.length > 0) {

                    // Mostriamo le associazioni tra paziente e patologie
                    this.patients_pathologies = patients_pathologies
                    // Svuotiamo lista dei pazienti
                    this.patients = []

                }
                else {

                    // Svuotiamo lista tra paziente e patologie
                    this.patients_pathologies = []

                    this.$toast.open({
                        message: "Nessuna patologia associata",
                        type: "warning"
                    })
                }

            },
            updateCalendar() {

                // Ricerca degli appuntamenti
                this.readAppointments()
                // Set giorno da visualizzare ad oggi
                this.calendar.config.selectedDate = moment().format("YYYY-MM-DD")

            },
            updateDayOfAppointment(dateSelected) {
               
                this.form.appointment.date = moment(dateSelected).format(`YYYY-MM-DD`)
                this.form.appointment.appointment_day_after_date = moment(dateSelected).format(`YYYY-MM-DD`)

            },
            updateTimeEndAppointment() {
                if(this.classCal == 1){
                    
                    if(this.visit_type > 0){
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")
                                         
                    } else {
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.getAppointmentDuration(), "minutes").format("HH:mm")               
                    }                     
                                           
                } else {

                    if(this.visit_type > 0){
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")                      
                    } else {
                        this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.getAppointmentDuration(), "minutes").format("HH:mm")               
                    }    
                                   
                    if(this.check_if_present_appointment()) {

                        const message = "L'appuntamento che stai creando va in conflitto con un altro appuntamento!"

                        this.validation.conflict = message
                        this.disabled = 1
                        } else if(this.check_room_hours_available()){
                        const message = "La stanza non è disponibile in questa fascia oraria!"

                        this.$toast.open({
                            message,
                            type: "warning"
                        })

                        this.validation.conflict = message
                        this.disabled = 1     
                        } else {
                        this.validation.conflict = false
                        this.disabled = 0                       
                    }         
   
                }    
            },

            functionSpecialEnabled(id_function) {

                // Se sono presenti abilitazioni all'utilizzo delle funzioni software
                if(store.getters.softwareSpecialFunctions) {
                    //sFunction == software function
                    for(let sFunction of store.getters.softwareSpecialFunctions) {
                        // Se la funzione software corrisponde all'ID passato
                        if(sFunction.id_software_special_function == id_function && sFunction.abilitation) {
                            return true
                        }
                    }
                }

                return false

            },
            setAppointmentParams(data_selezionata) {

                this.form.appointment.date    = moment(data_selezionata).format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_date     = moment(data_selezionata).add(1, "day").format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_time    = moment(data_selezionata).format("HH:mm") 
                this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date
                
                if(this.check_last_appointment() != null){
                    var fulldate = this.check_last_appointment();                         

                    this.form.appointment.h_start = moment(fulldate).format(`HH:mm`)
                    this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")
                } else {
                    this.form.appointment.h_start = moment(data_selezionata).format("HH:mm")    
                }     
                      
                this.loaders.creating = false;
                
                this.updateTimeEndAppointment()

                // Controlliamo esistenza appuntamenti gia presenti nella fascia oraria
                /*if(this.check_if_present_appointment()) {
                    this.$toast.open({
                        message: "L'appuntamento che stai creando va in conflitto con un altro appuntamento!",
                        type: "warning"
                    })
                }*/

                // Trigger di vuelidate per controllo sui campi input
                this.$v.$touch()
            },
            getAppointmentDuration() {
                return this.patients_pathologies.find(
                    patient_pathology_arr => patient_pathology_arr.id == this.form.appointment.id_patient_pathology
                ).sessions_duration
            },
            update_therapies(id_patient_pathology_selected) {

                // Impostiamo se è stata settata l'id della terapia associata tra paziente -> patologia in pathients_pathologies
                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == id_patient_pathology_selected) {
                        this.form.patients_pathologies.id_therapy = patient_pathology.id_therapy
                        this.therapy_program                      = patient_pathology.therapy_program
                        this.form.patients_pathologies.id         = patient_pathology.id
                        this.form.form_edit_therapy.title_old_therapy = patient_pathology.therapy ? patient_pathology.therapy.title : null
                        this.form.form_edit_therapy.therapy_on_select = patient_pathology.therapy ? patient_pathology.therapy.title : null
                    }
                }

            },
            change_title_new_therapy(id_therapy) {

                if(!id_therapy) {
                    this.form.form_edit_therapy.therapy_on_select = null
                }
                else {

                    // Ciclo delle terapie per ricavare il titolo
                    for(const therapy of this.therapies.DB) {
                        if(therapy.id == id_therapy) {
                            this.form.form_edit_therapy.therapy_on_select = therapy.title
                        }
                    }

                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            },
            set_params_for_appointment_urgent() {

                // Controlliamo stato flag
                if(this.form.appointment.urgent) {

                    this.change_date_now_in_calendar("SET")
                    //this.turn_off_therapy_program()
                    this.turn_off_notifications()

                    this.$toast.open({
                        message: "Hai impostato il prelievo come urgente!",
                        type: "success"
                    })

                }
                else {

                    this.change_date_now_in_calendar("POP")

                    this.$toast.open({
                        message: "Hai impostato il prelievo come normale",
                        type: "success"
                    })

                }

            },
            change_date_now_in_calendar(action) {

                if(action == "SET") {

                    // Impostiamo data e orario di adesso
                    this.form.appointment.appointment_day_after_date    = moment().format("YYYY-MM-DD")
                    this.form.appointment.appointment_day_after_time    = moment().format("HH:mm")

                    // Impostazione automatica del termine appuntamento
                    this.updateTimeEndAppointment()

                }
                else if(action == "POP") {

                    // Rimuoviamo data e orari di adesso
                    this.form.appointment.appointment_day_after_date    = ""
                    this.form.appointment.appointment_day_after_time    = ""
                    this.form.appointment.appointment_day_after_time_end = ""

                }
                else {
                    console.alert("action not specified")
                }

            },
            turn_off_therapy_program() {

                this.req_activation_therapy_program = false

                this.$toast.open({
                    message: "Programma teraupetico non attivo",
                    type: "warning"
                })

            },
            turn_off_notifications() {

                this.notify.phone = false
                this.notify.email = false
                this.notify.phonecg = false
                this.notify.emailcg = false               

                this.$toast.open({
                    message: "Notifiche non attive",
                    type: "warning"
                })

            },

 
            check_if_present_appointment() {

                // Parametri selezionati
                var {
                    h_start,
                    h_end,
                    date
                } = this.form.appointment

                for(const appointment of this.getEventsByRoomSelected(false)) {   
                    // Parametri appuntamento
                    var date_a    = moment(appointment.date).format("YYYY-MM-DD")
                    var h_start_a = moment(appointment.h_start).format("HH:mm")
                    var h_end_a   = moment(appointment.h_end).format("HH:mm")                

                    date = date.replaceAll('-', '');
                    date_a = date_a.replaceAll('-', '');
                    h_start = h_start.replaceAll(':', '');
                    h_start_a = h_start_a.replaceAll(':', '');
                    h_end = h_end.replaceAll(':', '');
                    h_end_a = h_end_a.replaceAll(':', '');

                    const conflitto = (
                        date_a == date
                    ) &&
                    (
                        h_end_a > h_start && h_start_a < h_end
                    )

                    /*const conflitto = (
                        date_a == date
                    ) &&
                    (
                        (
                            (
                                h_start > h_start_a
                            ) && 
                            (
                                h_start < h_end_a
                            )
                        ) ||
                        (
                            (
                                h_start < h_start_a
                            ) && 
                            (
                                h_end_a < h_end ||
                                h_end_a > h_end
                            )
                        )
                    )  */                 

                    if(conflitto) return true
                    
                }
            },
            async check_if_present_appointment_patient(){
                try {
                    const CF = this.form.user.CF.toUpperCase()

                    let patient = await this.axios.get(`user/patients/read/detail/${CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    if(patient.data.patients_pathologies) { 

                        // Popoliamo array degli appuntamenti estrapolandoli dalle terapie seguite
                        for(let patient_pathology of patient.data.patients_pathologies) {  
                            for(let appointment of patient_pathology.appointment) {
                                this.appointments_patient.DB.push(appointment)
                            }                          
                        }

                    }

                    if(this.check_dates_patient()) {
                        const message = "Il paziente ha già un appuntamento nella data selezionata"

                        this.validation.conflict = message
                        this.disabled = 1
                    } else {
                        this.validation.conflict = false
                        this.disabled = 0                                
                    }                     
                } catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })


                }
            },

            check_dates_patient(){
                // Parametri selezionati
                var {
                    date
                } = this.form.appointment


                for(const appointment of this.appointments_patient.DB) {   
                    // Parametri appuntamento
                    var date_a    = moment(appointment.date).format("YYYY-MM-DD")

                    date = date.replaceAll('-', '');
                    date_a = date_a.replaceAll('-', '');

                    const conflitto = (
                        date_a == date
                    )          

                    if(conflitto) return true
                }    

            },
          
    
            easterDateLLongre(Y) {
                let M=3, G= Y % 19+1, C= ~~(Y/100)+1, L=~~((3*C)/4)-12,
                E=(11*G+20+ ~~((8*C+5)/25)-5-L)%30, D;
                E<0 && (E+=30);
                (E==25 && G>11 || E==24) && E++;
                (D=44-E)<21 && (D+=30);
                (D+=7-(~~((5*Y)/4)-L-10+D)%7)>31 && (D-=31,M=4);

                if(D == 31){
                    D = 1;
                    M = M+1
                } else {
                    D = D+1;
                }  

                if(M < '10'){ 
                    M = '0' + M;
                } 
  
                if(D < '10'){ 
                    D = '0' + D;
                }                 
                return Y + '-' + M + '-' + D;
            }   

        },
        mounted() {
            this.read_blood_test_types()       
        },
        computed: {
            therapies_by_pathology() {

                let therapies = []

                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                        if(patient_pathology.pathology.pathologies_therapies) therapies = patient_pathology.pathology.pathologies_therapies
                    }
                }

                return therapies

            },
            therapy_cronology() {

                let cronology = []

                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == this.form.appointment.id_patient_pathology) {
                        if(patient_pathology.pathology.pathologies_therapies) cronology = patient_pathology.therapy_cronologies
                    }
                }

                //console.log("this.patients_pathologies", this.patients_pathologies)

                return cronology

            }
        }      
    }

</script>