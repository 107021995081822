<template>
    <div class="container-fluid">

        <div id="stile">

        </div>

        <div class="row">
            <div class="col-10 py-3 bg-fafafa">

                <loader v-if="loaders.reading.appointments" />

                <div v-show="!loaders.reading.appointments">

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div v-if="view.mode == `calendar` && list_pharma == 0" class="mt-2">

                        <div class="row mb-2">
                            <div class="col-md-6 col-lg-3 col-xl-2">
                                <div class="card card-body mb-2 mb-lg-0 h-max-160-px overflow-auto">

                                    <p class="mb-2 font-weight-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-open mb-1" viewBox="0 0 16 16">
                                            <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                                            <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                                        </svg> Stanze
                                    </p>

                                    <div v-for="(room, index) in orderedRooms" v-bind:key="`filter_room_${index}`" v-bind:value="index" class="custom-control custom-checkbox text-truncate" v-bind:class="{'border-bottom': index+1 != orderedRooms.length}">
                                        <input type="checkbox" class="custom-control-input" :id="`room_${room.number}`" v-model="filters.rooms[room.id]" v-on:change="onChangeProcessed($event, room.id)" @change="search_appointments_by_filters_of_calendar_view">
                                        <label class="custom-control-label text-uppercase" :for="`room_${room.number}`" :style="`color: ${room.color}`"><strong>{{room.title}}</strong></label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-5">
                                <div class="card card-body mb-2 mb-lg-0 h-max-160-px overflow-auto">
                                
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <p class="mb-2 font-weight-bold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-medical mb-1" viewBox="0 0 16 16">
                                                    <path d="M8.5 4.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L7 6l-.549.317a.5.5 0 1 0 .5.866l.549-.317V7.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L9 6l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V4.5zM5.5 9a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
                                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                                                </svg> Patologie
                                            </p>
                                        </div>
                                        <div class="col-lg-8">
                                            <input type="text" class="search_filter w-100" placeholder="Cerca..." v-model="filters.pathology_input_search">
                                        </div>
                                    </div>                                

                                    <div v-for="(pathology, index) in orderedPathologies" v-bind:key="`filter_pathology_${index}`" v-bind:value="index" class="custom-control custom-checkbox text-truncate" v-bind:class="{'border-bottom': index+1 != orderedPathologies.length}" :title="pathology.title">
                                        
                                        <input type="checkbox" class="custom-control-input" :id="`pathology_${pathology.title}`" v-model="filters.pathologies[pathology.id]" @change="search_appointments_by_filters_of_calendar_view">
                                        
                                        <label class="custom-control-label pb-2" :for="`pathology_${pathology.title}`">
                                            <b class="text-monospace">{{pathology.id_pathology}}</b> {{pathology.title}}
                                        </label>

                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3 col-xl-2">
                                <div class="card card-body mb-2 mb-lg-0 h-max-160-px overflow-auto">

                                    <p class="mb-2 font-weight-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity mb-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                                        </svg> Terapie
                                    </p>

                                    <div v-for="(therapy, index) in orderedTherapies" v-bind:key="`filter_therapy_${index}`" v-bind:value="index" class="custom-control custom-checkbox text-truncate" v-bind:class="{'border-bottom': index+1 != orderedTherapies.length}">
                                        <input type="checkbox" class="custom-control-input" :id="`therapy_${therapy.title}`" v-model="filters.therapies[therapy.id]" @change="search_appointments_by_filters_of_calendar_view">
                                        <label class="custom-control-label pb-2" :for="`therapy_${therapy.title}`">{{therapy.title}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6 col-lg-12 mt-lg-1 col-xl-3 mt-xl-0 d-flex align-items-stretch">
                                <div class="card card-body h-100 mb-3 mb-lg-0">

                                    <p class="font-weight-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-line mb-1" viewBox="0 0 16 16">
                                            <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z"/>
                                        </svg> Contatori
                                    </p>

                                    <div class="row">
                                        <div class="col-6">
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{appointmentsDay}}
                                                </span> Oggi
                                            </p>
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{DB.appointments.length}}
                                                </span> Totale
                                            </p>
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_nessunostato}}
                                                </span> Nessuno stato
                                            </p>
                                        </div>
                                        <div class="col-6">
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_insaladiaspetto}}
                                                </span> In sala
                                            </p>
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_pausa}}
                                                </span> In pausa
                                            </p>
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_invisita}}
                                                </span> In visita
                                            </p>
                                            <p class="mb-1 text-truncate">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_visitacompletata}}
                                                </span> Completata
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="custom-control custom-checkbox text-truncate ml-3">
                                <input type="checkbox" class="custom-control-input" style="z-index:1 !important" id="view_therapy_today" v-model="filters.therapies_today" @change="search_appointments_therapies_today">
                                <label class="custom-control-label text-uppercase" :for="view_therapy_today"><strong>Visualizza le terapie</strong></label>
                            </div>
                          
                        </div>                                            
                        <div v-if="patient_waiting_therapy == 1 && functionSpecialEnabled(7)" v-cloak class="alert alert-danger mt-2 f-size-1_6-em effetto-lampeggia-loop">
                            Pazienti in attesa di invio alla terapia!
                        </div>
                        <div v-if="pharma_waiting_therapy == 1 && functionSpecialEnabled(14)" v-cloak class="alert alert-danger mt-2 f-size-1_6-em effetto-lampeggia-loop">
                            Farmaci in attesa di essere preparati!
                        </div>  
                        <div v-if="patient_waiting_take_charge == 1 && functionSpecialEnabled(13)" v-cloak class="alert alert-danger mt-2 f-size-1_6-em effetto-lampeggia-loop">
                            Terapie da prendere in carico
                        </div>    
                        <div v-if="patient_waiting_verify == 1 && functionSpecialEnabled(12)" v-cloak class="alert alert-danger mt-2 f-size-1_6-em effetto-lampeggia-loop">
                            Terapie da verificare
                        </div>                                                                                               
                        <template v-if="this.classCal == 2">
                            <div class="container-cal"></div>
                        </template> 
                        <vue-cal 
                            locale               = "it"
                            :active-view         = "calendar.config.activeView"
                            :min-date            = "calendar.config.minDate"
                            :time-step           = "calendar.config.timeStep"
                            :events              = "calendar.config.events"
                            :time-from           = "calendar.config.timeFrom"
                            :time-to             = "calendar.config.timeTo"
                            :selectedDate        = "calendar.config.selectedDate"
                            :hide-weekdays       = "calendar.config.hideWeekDays"
                            :disable-views       = "['years']"
                            :transitions          = "false"
                            :special-hours        = getRoomAvailibilities
                            events-on-month-view = "short"
                            :on-event-click      = "updateDayOfAppointment"
                            class                = "card-calendar"
                            events-count-on-year-view
                            @ready               = "ready_calendar"
                            @view-change         = "change_calendar"
                        />

                    </div>
                    
                    <div v-if="view.mode == `standard` && list_pharma == 0" class="mt-2">

                        <div class="row pb-3">
                            <div class="col-sm-6 col-lg-6 mb-3 mb-lg-0 col-xl-2">
                                <div class="card card-body mb-3 mb-lg-0 h-100 d-flex justify-content-center">

                                    <form>
                                        <div class="form-group row mb-1">

                                            <label for="filter_from_date" class="col-form-label ">Dal</label>

                                            <div class="pl-1 col-xl-12">
                                                <input type="date" class="form-control" id="filter_from_date" v-model="filters.date.from" @change="search_appointments_by_filters_of_standard_view" required>
                                            </div>

                                        </div>
                                        <div class="form-group row mb-0">

                                            <label for="filter_to_date" class="col-form-label">Al</label>

                                            <div class="pl-1 col-xl-12">
                                                <input type="date" class="form-control" id="filter_to_date" :min="filters.date.from" v-model="filters.date.to" @change="search_appointments_by_filters_of_standard_view" required>
                                            </div>

                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6 mb-3 mb-lg-0" :class="{'col-xl-3': functionSpecialEnabled(5), 'col-xl-4': !functionSpecialEnabled(5)}">
                                <div class="card card-body mb-3 mb-lg-0 h-100 d-flex justify-content-center">

                                    <form>
                                        <div>
                                            <p class="mb-2 font-weight-bold">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-open mb-1" viewBox="0 0 16 16">
                                                    <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                                                    <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                                                </svg> Stanze
                                            </p>
                                            <div v-for="(room, index) in orderedRooms" v-bind:key="`filter_room_${index}`" v-bind:value="index" class="custom-control custom-checkbox text-truncate" v-bind:class="{'border-bottom': index+1 != orderedRooms.length}">
                                                <input type="checkbox" class="custom-control-input" :id="`room_sv_${room.number}`" v-model="filters.rooms[room.id]" v-on:change="onChangeProcessed($event, room.id)" @change="search_appointments_by_filters_of_standard_view">
                                                <label class="custom-control-label text-uppercase" :for="`room_sv_${room.number}`">{{room.title}}</label>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-6" :class="{'col-xl-4': functionSpecialEnabled(5), 'col-xl-6': !functionSpecialEnabled(5)}">
                                <div class="card card-body h-100 mb-3 mb-lg-0">
                                    <div class="row">
                                        <div class="col-lg-6">                                            
                                            <p class="mb-1">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{appointmentsDay}}
                                                </span> Oggi
                                            </p>
                                            <p class="mb-1">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{DB.appointments.length}}
                                                </span> Totale
                                            </p>
                                            <p class="mb-1">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_nessunostato}}
                                                </span> Nessuno stato
                                            </p>
                                            <p class="mb-1">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_insaladiaspetto}}
                                                </span> In sala
                                            </p>
                                            <p class="mb-1">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_invisita}}
                                                </span> In visita
                                            </p>
                                            <p class="mb-1">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_pausa}}
                                                </span> In pausa
                                            </p>
                                            <p class="mb-0">
                                                <span class="f-family-monospace font-weight-bold">
                                                    {{count_appointments_visitacompletata}}
                                                </span> Completata
                                            </p>
                                        </div>
                                        <div class="col-lg-6 d-flex">
                                            <doughnut
                                                ref         = "skills_chart"
                                                :chart-data = "charts.doughnut.data"
                                                :options    = "charts.doughnut.options"
                                                class       = "m-auto w-100"
                                                style       = "max-height: 160px"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6 col-xl-3 mb-3 mb-lg-0" v-if="functionSpecialEnabled(5)">
                                <div class="card card-body mb-3 mb-lg-0 h-100 d-flex justify-content-center">

                                    <div>
                                        <p class="mb-2 font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock mb-1" viewBox="0 0 16 16">
                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                            </svg> Medie
                                        </p>
                                        <div class="row mb-1 f-size-0_8-em">
                                            <div class="col-4 text-left">
                                                Stanza
                                            </div>                                            
                                            <div class="col-4 text-left">
                                                Attesa
                                            </div>
                                            <div class="col-4 f-size-0_8_em text-left">
                                                Visita
                                            </div>
                                        </div>
                                        <div class="row mb-1 f-size-0_8-em" v-for="(room, index) in roomForStadardView" :key="`room_average_${index}`">
                                                <div class="col-4 border-right f-family-monospace text-left text-uppercase font-weight-bold">
                                                    {{room.number}}
                                                </div>
                                            <div class="col-4 f-family-monospace font-weight-bold text-truncate text-left border-right">
                                                {{appointments_average_time(room.appointments).waiting}}
                                            </div>
                                            <div class="col-4 f-family-monospace font-weight-bold text-truncate text-left">
                                                {{appointments_average_time(room.appointments).visit}}
                                            </div>
                                        </div>
                                        <p class="m-0 text-right text-muted">
                                            <small>
                                                Valori in minuti
                                            </small>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="alert alert-danger" v-if="Object.keys(roomForStadardView).length < 1 && !loaders.reading.appointments">
                            Nessun appuntamento con i parametri ricercati
                        </div>
                        <div class="alert alert-info" v-show="loaders.reading.appointments">
                            Ricerca appuntamenti..
                        </div>
                        
                        <div class="card card-body mb-3" v-for="(room, index) in roomForStadardView" :key="`room_${index}`" v-show="!loaders.reading.appointments">

                            <div class="row font-weight-bold mb-2" :style="`color: ${room.color}`">
                                <div class="col-6">
                                    <span class="text-uppercase">
                                        STANZA: {{index}}
                                    </span>
                                </div>
                                <div class="col-6 text-right f-family-monospace">
                                    <span class="f-size-1-em">
                                        {{room.appointments.length}}
                                    </span>
                                </div>
                            </div>                           

                            <div class="alert alert-danger m-0" v-if="room.appointments.length < 1">
                                Nessun appuntamento
                            </div>

                            <div v-else>

                                <div class="table-responsive m-0">
                                    <table class="table table-sm table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">COD.</th>
                                                <th scope="col">Data</th>
                                                <th scope="col" @click="
                                                    makeOrder(
                                                        'h_start'
                                                    )
                                                ">
                                                    <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                    </svg>
                                                    <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                    </svg>
                                                    <span class="ml-1">
                                                        Ora
                                                    </span>
                                                </th>
                                                <th scope="col" @click="
                                                    makeOrder(
                                                        'patient_pathology.patient.surname'
                                                    )
                                                ">
                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                    </svg>
                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                    </svg>
                                                    <span class="ml-1">
                                                        Soggetto
                                                    </span>
                                                </th>
                                                <th scope="col">Patologia</th>
                                                <th scope="col">Terapia</th>
                                                <th scope="col">Attesa</th>
                                                <th scope="col">Stato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(appointment, index) of room.appointments" :key="`appointment_${appointment.id}`" v-bind:class="{
                                                'bg_nocome':    !appointment.totem_presence && !appointment.room_presence,
                                                'bg_room':     (appointment.totem_presence && !appointment.room_presence) || appointment.patient_pause_back,
                                                'bg_visiting': appointment.room_presence && !appointment.patient_pause && !appointment.appointment_closed,
                                                'bg_pause':    appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed,
                                                'bg_finish':   appointment.appointment_closed
                                            }" @click="updateDayOfAppointment(appointment)">
                                                <th width="1%" scope="row">{{index + 1}}</th>
                                                <th width="1%" scope="row">{{appointment.totem_number ? appointment.totem_number : '-'}}</th>
                                                <td width="1%">{{appointment.date | data_slash}}</td>
                                                <td width="7%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                <td width="30%">{{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}</td>
                                                <td width="65%">{{appointment.patient_pathology.pathology.id_pathology}} {{appointment.patient_pathology.pathology.title}}</td>
                                                <td width="1%" class="text-center">{{appointment.patient_pathology.id_therapy ? appointment.patient_pathology.therapy.title : "-"}}</td>
                                                <!--<td width="1%" class="text-right">{{appointment.totem_presence_arrival && !appointment.room_presence && !appointment.appointment_closed ? time_difference_from_time(appointment.h_start) : "--:--"}}</td>-->
                                                <td width="1%" class="text-right">
                                                    {{time_difference_from_time(appointment.totem_presence_arrival, false, true, appointment.room_presence_arrival) > 0 && appointment.totem_presence ? time_difference_from_time(appointment.totem_presence_arrival, true, true, appointment.room_presence_arrival) : '--:--'}}
                                                </td>
                                                <td width="1%" class="text-center">                                      
                                                    <div v-if="appointment.icon == 'nurse'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6 .3 5.3 .3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V136c0-2.7 .1-5.4 .3-8H96zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80V160H144v16zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6zM208 48V64H192c-4.4 0-8 3.6-8 8V88c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8H240V48c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8z"/></svg>                            
                                                       
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'chairs'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>                            
                                                    </div>                                                        
                                                    <div v-else-if="appointment.icon == 'therapyOn'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>     
                                                       
                                                    </div>
                                                    <div v-else-if="appointment.icon == 'therapyStart'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>     
                                                       
                                                    </div>                                                                                                         
                                                    <div v-else-if="appointment.icon == 'takeCharge'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
                                                       
                                                    </div> 
                                                    <div v-else-if="appointment.icon == 'preparation'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto"  viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>                                               
                                                       
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'totem'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 16 16">
                                                            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                        </svg>                                                       
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'room'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                                        </svg>    
                                                    </div>
                                                    <div v-else-if="appointment.icon == 'notBack'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                        </svg>
                                                    </div>
                                                    <div v-else-if="appointment.icon == 'back'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
                                                        </svg>
                                                    </div>   
                                                    <div v-else-if="appointment.icon == 'closed'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                        </svg>                               
                                                    </div>
                                                    <div v-else-if="appointment.icon == 'notShown'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"/>
                                                        </svg>
                                                    </div>  
                                                    <div v-else>
                                                        -
                                                    </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                        <!-- Rapporto -->
                        <vue-html2pdf

                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "rapporto"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2Pdf_rapporto"

                        >

                            <section slot="pdf-content" class="f-size_PDF">

                                <section v-for="(room, index) in roomsForPDF" :key="`room_rapporto_${index}`">

                                <!-- rooms[appointment.rooms.title].pages_appointments[length-1].appointments.push(appointment) -->

                                    <section class="f-family-monospace">

                                        <p class="text-uppercase font-weight-bold mb-2">
                                            STANZA: {{index}}
                                        </p>

                                        <section v-for="(page_appointment, index_2) of room.pages_appointments" :key="`room_${index}_page_${index_2}`">

                                            <table class="table table-striped table-bordered table-sm m-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Paziente</th>
                                                        <th scope="col">Data</th>
                                                        <th scope="col">Ora</th>
                                                        <th scope="col">Arrivo</th>
                                                        <th scope="col">Chiamata</th>
                                                        <th scope="col">Attesa appuntamento</th>
                                                        <th scope="col">Inizio pausa</th>
                                                        <th scope="col">Fine pausa</th>
                                                        <th scope="col">Termine</th>
                                                        <th scope="col">Durata</th>
                                                        <th scope="col">Attesa arrivo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="appointment of page_appointment.appointments" :key="`appointment_rapporto_${appointment.id}`">
                                                        <td class="text-truncate">
                                                            {{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.date | data_slash}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.h_start | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.totem_presence_arrival | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.room_presence_arrival | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{time_difference_from_time(
                                                                appointment.h_start,
                                                                false,
                                                                true,
                                                                appointment.room_presence_arrival
                                                            )}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.pause_start_time | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.pause_end_time | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.appointment_closed ? appointment.appointment_closed.date : "" | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{appointment.appointment_closed ? time_difference_from_time(
                                                                appointment.room_presence_arrival,
                                                                false,
                                                                true,
                                                                appointment.appointment_closed.date
                                                            ) : ""}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{time_difference_from_time(
                                                                appointment.totem_presence_arrival,
                                                                false,
                                                                true,
                                                                appointment.room_presence_arrival
                                                            )}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div v-if="index_2 == room.pages_appointments.length-1">
                                                <p class="text-right m-0 font-weight-bold">
                                                    Media attesa {{appointments_average_time(
                                                        get_appointments_from_paginations(
                                                            room.pages_appointments
                                                        )
                                                    ).waiting}}
                                                </p>
                                                <p class="text-right m-0 font-weight-bold">
                                                    Media visite {{appointments_average_time(
                                                        get_appointments_from_paginations(
                                                            room.pages_appointments
                                                        )
                                                    ).visit}}
                                                </p>
                                            </div>

                                            <div class="html2pdf__page-break" />
                                            
                                        </section>

                                    </section>
                                    
                                </section>

                            </section>

                        </vue-html2pdf>
                            
                        <!-- Scarico -->
                        <vue-html2pdf
                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "scarico"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2Pdf"

                        >

                            <section slot="pdf-content" class="container">

                                <div class="row mb-3">
                                    <div class="col-lg-6">
                                        <img src="../../../assets/ifo_logo_3.png" class="w-100">
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <p class="m-0">
                                            <small>
                                                Scarico giornaliero <b>Ematoga</b>
                                            </small>
                                        </p>
                                        <p class="mt-neg-5_5">
                                            <small class="text-monospace">
                                                {{pdf.today | data_slash}}
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <p>
                                    <small>
                                        Dalla data <b>{{filters.date.from | data_slash}}</b> alla data <b>{{filters.date.to | data_slash}}</b>
                                    </small>
                                </p>

                                <section class="mb-3 pdf-item" v-for="(room, index) in roomForStadardView" :key="`room_${index}`">

                                    <p class="font-weight-bold m-0">
                                        <span class="text-uppercase" :style="`color: #5c1b24`">STANZA: {{index}}</span>
                                    </p>

                                    <div class="alert alert-danger m-0" v-if="room.appointments.length < 1">
                                        Nessun appuntamento
                                    </div>

                                    <div v-else>

                                        <div class="container-fluid">

                                            <section class="row pdf-item">
                                                <div class="col-lg-6 col-xl-2 text-truncate pl-1">
                                                    <small>
                                                        <strong>Data</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <strong>Paziente</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <strong>Patologia</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-2 text-truncate pr-0">
                                                    <small>
                                                        <strong>Terapia</strong>
                                                    </small>
                                                </div>
                                            </section>

                                            <section class="row border-bottom pdf-item" v-for="appointment of room.appointments" :key="`appointment_${appointment.id}`" v-bind:class="{'bg-1303fc': appointment.totem_presence, 'bg-fc03b1': appointment.room_presence, 'bg-03fc1c': appointment.appointment_closed}">
                                                
                                                <div class="col-lg-6 col-xl-2 text-truncate pl-1">
                                                    <small class="f-family-monospace">
                                                        {{appointment.date | data_slash}} {{appointment.h_start | data_ora}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        {{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <span class="f-family-monospace font-weight-bold">{{appointment.patient_pathology.pathology.id_pathology}}</span> {{appointment.patient_pathology.pathology.title}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-2 text-truncate pr-0">
                                                    <small>
                                                        {{appointment.patient_pathology.id_therapy ? appointment.patient_pathology.therapy.title : `-`}}
                                                    </small>
                                                </div>

                                            </section>

                                        </div>

                                    </div>

                                </section>

                            </section>

                        </vue-html2pdf>

                    </div>
                    
                    <div v-if="view.mode == `blood_tests`" class="mt-2">

                        <div class="row pb-3">
                            <div class="col-sm-6 col-lg-6 mb-3 mb-lg-0 col-xl-12">
                                <div class="card card-body mb-3 mb-lg-0 h-100 d-flex justify-content-center">

                                    <form>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group row mb-1">

                                                    <label for="filter_from_date" class="col-form-label ">Dal</label>

                                                    <div class="pl-1 col-xl-12">
                                                        <input type="date" class="form-control" id="filter_from_date" v-model="filters.date.from" @change="search_appointments_by_filters_of_blood_test_view" required>
                                                    </div>

                                                </div>
                                            </div> 
                                            <div class="col-lg-6">  
                                                <div class="form-group row mb-0">

                                                    <label for="filter_to_date" class="col-form-label">Al</label>

                                                    <div class="pl-1 col-xl-12">
                                                        <input type="date" class="form-control" id="filter_to_date" :min="filters.date.from" v-model="filters.date.to" @change="search_appointments_by_filters_of_blood_test_view" required>
                                                    </div>

                                                </div>
                                            </div>     
                                        </div>    
                                    </form>

                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger" v-if="Object.keys(bloodTestsForBloodTestsView).length < 1 && !loaders.reading.appointments_blood_test">
                            Nessun prelievo con le date ricercate
                        </div>
                        <div class="alert alert-info" v-show="loaders.reading.appointments_blood_test">
                            Ricerca prelievi..
                        </div>

                        <div class="alert alert-default m-0 text-left">
                            Legenda: 
                            <div class="row mt-2">
                                <div class="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                        <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"/>
                                    </svg> 
                                    Paziente non presentato   
                                </div>
                                <div class="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                    </svg>  
                                    Paziente in sala d'attesa    
                                </div> 
                                <div class="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512">
                                        <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                    </svg>                                       
                                    Prelievo effettuato    
                                </div>
                                <div class="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                        <path d="M0 48C0 21.5 21.5 0 48 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V48zM416 256H544V237.3L466.7 160H416v96zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 80v48l-48 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h48c8.8 0 16-7.2 16-16V144c0-8.8-7.2-16-16-16H240V80c0-8.8-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z"/>
                                    </svg>                                                                      
                                    Prelievo urgente    
                                </div>                               
                            </div>


                        </div>   

                        <div class="card card-body mb-3 mt-2" v-for="(blood_test, index) in bloodTestsForBloodTestsView" :key="`blood_test_${index}`" v-show="!loaders.reading.appointments_blood_test">
                            <div class="row font-weight-bold mb-2">
                                <div class="col-6">
                                    <span class="text-uppercase">
                                        {{blood_test.title}} {{blood_test.date}}
                                    </span>
                                </div>
                                <div class="col-6 text-right f-family-monospace">
                                    <span class="f-size-1-em">
                                        {{blood_test.appointments.length}}
                                    </span>
                                </div>
                            </div>   
                                                    

                            <div class="alert alert-danger m-0" v-if="blood_test.appointments.length < 1">
                                Nessun prelievo
                            </div>
                            

                            <div v-else>
                                
                                <div class="table-responsive m-0">
                                    <table class="table table-sm table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">COD.</th>
                                                <th scope="col">Data</th>
                                                <th scope="col" @click="
                                                    makeOrder(
                                                        'h_start'
                                                    )
                                                ">
                                                    <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                    </svg>
                                                    <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                    </svg>
                                                    <span class="ml-1">
                                                        Ora inizio
                                                    </span>
                                                </th>
                                              
                                                <th scope="col" @click="
                                                    makeOrder(
                                                        'patient_pathology.patient.surname'
                                                    )
                                                ">
                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                    </svg>
                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                    </svg>
                                                    <span class="ml-1">
                                                        Paziente
                                                    </span>
                                                </th>
                                                <th scope="col">Patologia</th>
                                                <th scope="col">Terapia</th>
                                                <th scope="col">Attesa</th>
                                                <th scope="col">Stato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(appointment, index) of blood_test.appointments" :key="`appointment_${appointment.id}`" v-bind:class="{
                                                'bg_nocome':   !appointment.urgent && !appointment.totem_presence && !appointment.room_presence,
                                                'bg_room':     !appointment.urgent && appointment.totem_presence && !appointment.room_presence,
                                                'bg_visiting': !appointment.urgent && appointment.totem_presence && appointment.room_presence,
                                                'bg_visiting':   appointment.urgent && !appointment.nurse_closed_blood_test_date,
                                                'bg_finish':   appointment.nurse_closed_blood_test_date
                                            }" @click="updateDayOfAppointmentTherapies(appointment)">
                                                <th width="1%" scope="row">{{index + 1}}</th>
                                                <th width="1%" scope="row">{{appointment.progressive_number_blood_test ? appointment.progressive_number_blood_test : '-'}}</th>
                                                <td width="1%">{{appointment.date | data_slash}}</td>
                                                <td width="7%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                <td width="30%">{{appointment.appointment.patient_pathology.patient.surname}} {{appointment.appointment.patient_pathology.patient.name}}</td>
                                                <td width="65%">{{appointment.appointment.patient_pathology.pathology.id_pathology}} {{appointment.appointment.patient_pathology.pathology.title}}</td>
                                                <td width="1%" class="text-center">{{appointment.appointment.patient_pathology.id_therapy ? appointment.appointment.patient_pathology.therapy.title : "-"}}</td>
                                                <!--<td width="1%" class="text-right">{{appointment.totem_presence_arrival && !appointment.room_presence && !appointment.appointment_closed ? time_difference_from_time(appointment.h_start) : "--:--"}}</td>-->
                                                <td width="1%" class="text-right">
                                                    {{time_difference_from_time(appointment.totem_presence_arrival, false, true, appointment.room_presence_arrival) > 0 && appointment.totem_presence ? time_difference_from_time(appointment.totem_presence_arrival, true, true, appointment.room_presence_arrival) : '--:--'}}
                                                </td>
                                                <td width="1%" class="text-center">   
                                                    <div v-if="appointment.icon == 'therapyStart'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512">
                                                            <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                        </svg>                                                         
                                                    </div>                                                                                                          
                                                    <div v-else-if="appointment.icon == 'totem'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 16 16">
                                                            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                        </svg>                                                      
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'closed'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                                        </svg>                               
                                                    </div>
                                                    <div v-else-if="appointment.icon == 'notShown'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"/>
                                                        </svg>                    
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'urgent'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                                            <path d="M0 48C0 21.5 21.5 0 48 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V48zM416 256H544V237.3L466.7 160H416v96zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 80v48l-48 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h48c8.8 0 16-7.2 16-16V144c0-8.8-7.2-16-16-16H240V80c0-8.8-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z"/>
                                                        </svg>                  
                                                    </div> 

                                                    <div v-else>
                                                        -
                                                    </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                        <!-- Rapporto -->
                        <vue-html2pdf

                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "rapporto"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2Pdf_rapporto"

                        >

                            <section slot="pdf-content" class="f-size_PDF">

                                <section v-for="(room, index) in roomsForPDF" :key="`room_rapporto_${index}`">

                                <!-- rooms[appointment.rooms.title].pages_appointments[length-1].appointments.push(appointment) -->

                                    <section class="f-family-monospace">

                                        <p class="text-uppercase font-weight-bold mb-2">
                                            STANZA: {{index}}
                                        </p>

                                        <section v-for="(page_appointment, index_2) of room.pages_appointments" :key="`room_${index}_page_${index_2}`">

                                            <table class="table table-striped table-bordered table-sm m-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Paziente</th>
                                                        <th scope="col">Data</th>
                                                        <th scope="col">Ora</th>
                                                        <th scope="col">Arrivo</th>
                                                        <th scope="col">Chiamata</th>
                                                        <th scope="col">Attesa appuntamento</th>
                                                        <th scope="col">Inizio pausa</th>
                                                        <th scope="col">Fine pausa</th>
                                                        <th scope="col">Termine</th>
                                                        <th scope="col">Durata</th>
                                                        <th scope="col">Attesa arrivo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="appointment of page_appointment.appointments" :key="`appointment_rapporto_${appointment.id}`">
                                                        <td class="text-truncate">
                                                            {{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.date | data_slash}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.h_start | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.totem_presence_arrival | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.room_presence_arrival | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{time_difference_from_time(
                                                                appointment.h_start,
                                                                false,
                                                                true,
                                                                appointment.room_presence_arrival
                                                            )}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.pause_start_time | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.pause_end_time | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.appointment_closed ? appointment.appointment_closed.date : "" | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{appointment.appointment_closed ? time_difference_from_time(
                                                                appointment.room_presence_arrival,
                                                                false,
                                                                true,
                                                                appointment.appointment_closed.date
                                                            ) : ""}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{time_difference_from_time(
                                                                appointment.totem_presence_arrival,
                                                                false,
                                                                true,
                                                                appointment.room_presence_arrival
                                                            )}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div v-if="index_2 == room.pages_appointments.length-1">
                                                <p class="text-right m-0 font-weight-bold">
                                                    Media attesa {{appointments_average_time(
                                                        get_appointments_from_paginations(
                                                            room.pages_appointments
                                                        )
                                                    ).waiting}}
                                                </p>
                                                <p class="text-right m-0 font-weight-bold">
                                                    Media visite {{appointments_average_time(
                                                        get_appointments_from_paginations(
                                                            room.pages_appointments
                                                        )
                                                    ).visit}}
                                                </p>
                                            </div>

                                            <div class="html2pdf__page-break" />
                                            
                                        </section>

                                    </section>
                                    
                                </section>

                            </section>

                        </vue-html2pdf>
                            
                        <!-- Scarico -->
                        <vue-html2pdf
                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "scarico"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2Pdf"

                        >

                            <section slot="pdf-content" class="container">

                                <div class="row mb-3">
                                    <div class="col-lg-6">
                                        <img src="../../../assets/ifo_logo_3.png" class="w-100">
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <p class="m-0">
                                            <small>
                                                Scarico giornaliero <b>Ematoga</b>
                                            </small>
                                        </p>
                                        <p class="mt-neg-5_5">
                                            <small class="text-monospace">
                                                {{pdf.today | data_slash}}
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <p>
                                    <small>
                                        Dalla data <b>{{filters.date.from | data_slash}}</b> alla data <b>{{filters.date.to | data_slash}}</b>
                                    </small>
                                </p>

                                <section class="mb-3 pdf-item" v-for="(room, index) in roomForStadardView" :key="`room_${index}`">

                                    <p class="font-weight-bold m-0">
                                        <span class="text-uppercase" :style="`color: #5c1b24`">STANZA: {{index}}</span>
                                    </p>

                                    <div class="alert alert-danger m-0" v-if="room.appointments.length < 1">
                                        Nessun appuntamento
                                    </div>

                                    <div v-else>

                                        <div class="container-fluid">

                                            <section class="row pdf-item">
                                                <div class="col-lg-6 col-xl-2 text-truncate pl-1">
                                                    <small>
                                                        <strong>Data</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <strong>Paziente</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <strong>Patologia</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-2 text-truncate pr-0">
                                                    <small>
                                                        <strong>Terapia</strong>
                                                    </small>
                                                </div>
                                            </section>

                                            <section class="row border-bottom pdf-item" v-for="appointment of room.appointments" :key="`appointment_${appointment.id}`" v-bind:class="{'bg-1303fc': appointment.totem_presence, 'bg-fc03b1': appointment.room_presence, 'bg-03fc1c': appointment.appointment_closed}">
                                                
                                                <div class="col-lg-6 col-xl-2 text-truncate pl-1">
                                                    <small class="f-family-monospace">
                                                        {{appointment.date | data_slash}} {{appointment.h_start | data_ora}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        {{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <span class="f-family-monospace font-weight-bold">{{appointment.patient_pathology.pathology.id_pathology}}</span> {{appointment.patient_pathology.pathology.title}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-2 text-truncate pr-0">
                                                    <small>
                                                        {{appointment.patient_pathology.id_therapy ? appointment.patient_pathology.therapy.title : `-`}}
                                                    </small>
                                                </div>

                                            </section>

                                        </div>

                                    </div>

                                </section>

                            </section>

                        </vue-html2pdf>

                        </div>                   

                    <div v-if="view.mode == `chairs`" class="mt-2">

                        <div class="row pb-3">
                            <div class="col-sm-6 col-lg-6 mb-3 mb-lg-0 col-xl-12">
                                <div class="card card-body mb-3 mb-lg-0 h-100 d-flex justify-content-center">

                                    <form>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group row mb-1">

                                                    <label for="filter_from_date" class="col-form-label ">Dal</label>

                                                    <div class="pl-1 col-xl-12">
                                                        <input type="date" class="form-control" id="filter_from_date" v-model="filters.date.from" @change="search_appointments_by_filters_of_chairs_view" required>
                                                    </div>

                                                </div>
                                            </div> 
                                            <div class="col-lg-6">  
                                                <div class="form-group row mb-0">

                                                    <label for="filter_to_date" class="col-form-label">Al</label>

                                                    <div class="pl-1 col-xl-12">
                                                        <input type="date" class="form-control" id="filter_to_date" :min="filters.date.from" v-model="filters.date.to" @change="search_appointments_by_filters_of_chairs_view" required>
                                                    </div>

                                                </div>
                                            </div>     
                                        </div>    
                                    </form>

                                </div>
                            </div>
                        </div>

                        <div class="alert alert-danger" v-if="Object.keys(chairsForChairsView).length < 1 && !loaders.reading.appointments_chairs">
                            Nessuna poltrona con le date ricercate
                        </div>
                        <div class="alert alert-info" v-show="loaders.reading.appointments_chairs">
                            Ricerca poltrone..
                        </div>

                        <div class="alert alert-default m-0 text-left">
                            Legenda: 
                            <div class="row mt-2">
                                <div class="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                        <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"/>
                                    </svg> 
                                    Paziente non presentato   
                                </div>
                                <div class="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                    </svg>  
                                    Paziente in sala d'attesa    
                                </div> 
                                <div class="col-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                        <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/>
                                    </svg>     
                                    Terapia iniziata    
                                </div>   
                                <div class="col-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                        <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                    </svg>  
                                    Terapia completata    
                                </div>   
                                <div class="col-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 384 512">
                                        <path d="M0 32C0 14.3 14.3 0 32 0H352c17.7 0 32 14.3 32 32V64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64V32zm32 96H352V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zM160 240v48H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V352h48c8.8 0 16-7.2 16-16V304c0-8.8-7.2-16-16-16H224V240c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
                                    </svg>                                                                                      
                                    Assegnare farmaco    
                                </div>                                

                            </div>


                        </div>   

                        <div class="card card-body mb-3 mt-2" v-for="(chair, index) in chairsForChairsView" :key="`chair_${index}`" v-show="!loaders.reading.appointments_chairs">
                            <div class="row font-weight-bold mb-2">
                                <div class="col-6">
                                    <span class="text-uppercase">
                                        {{chair.title}} {{chair.number}}
                                    </span>
                                </div>
                                <div class="col-6 text-right f-family-monospace">
                                    <span class="f-size-1-em">
                                        {{chair.appointments.length}}
                                    </span>
                                </div>
                            </div>   
                                                    

                            <div class="alert alert-danger m-0" v-if="chair.appointments.length < 1">
                                Nessuna poltrona occupata
                            </div>
                            

                            <div v-else>
                                
                                <div class="table-responsive m-0">
                                    <table class="table table-sm table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">COD.</th>
                                                <th scope="col">Data</th>
                                                <th scope="col" @click="
                                                    makeOrder(
                                                        'h_start'
                                                    )
                                                ">
                                                    <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                    </svg>
                                                    <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                    </svg>
                                                    <span class="ml-1">
                                                        Ora inizio
                                                    </span>
                                                </th>
                                                <th scope="col">
                                                    
                                                    <span class="ml-1">
                                                        Ora fine
                                                    </span>
                                                </th>                                                
                                                <th scope="col" @click="
                                                    makeOrder(
                                                        'patient_pathology.patient.surname'
                                                    )
                                                ">
                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                    </svg>
                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                    </svg>
                                                    <span class="ml-1">
                                                        Paziente
                                                    </span>
                                                </th>
                                                <th scope="col">Patologia</th>
                                                <th scope="col">Terapia</th>
                                                <th scope="col">Attesa</th>
                                                <th scope="col">Stato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(appointment, index) of chair.appointments" :key="`appointment_${appointment.id}`" v-bind:class="{
                                                'bg_nocome':    !appointment.totem_presence && !appointment.room_presence,
                                                'bg_room':     (appointment.totem_presence && !appointment.room_presence) || appointment.appointment.patient_pause_back,
                                                'bg_visiting': appointment.room_presence && !appointment.patient_pause && !appointment.appointment.appointment_closed,
                                                'bg_pause':    appointment.appointment.patient_pause && !appointment.appointment.patient_pause_back && !appointment.appointment.appointment_closed,
                                                'bg_finish':   appointment.appointment.appointment_closed
                                            }" @click="updateDayOfAppointmentTherapies(appointment)">
                                                <th width="1%" scope="row">{{index + 1}}</th>
                                                <th width="1%" scope="row">{{appointment.progressive_number_therapy ? appointment.progressive_number_therapy : '-'}}</th>
                                                <td width="1%">{{appointment.date | data_slash}}</td>
                                                <td width="7%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                <td width="7%" class="text-center">{{appointment.h_end | data_ora}}</td>
                                                <td width="30%">{{appointment.appointment.patient_pathology.patient.surname}} {{appointment.appointment.patient_pathology.patient.name}}</td>
                                                <td width="65%">{{appointment.appointment.patient_pathology.pathology.id_pathology}} {{appointment.appointment.patient_pathology.pathology.title}}</td>
                                                <td width="1%" class="text-center">{{appointment.appointment.patient_pathology.id_therapy ? appointment.appointment.patient_pathology.therapy.title : "-"}}</td>
                                                <!--<td width="1%" class="text-right">{{appointment.totem_presence_arrival && !appointment.room_presence && !appointment.appointment_closed ? time_difference_from_time(appointment.h_start) : "--:--"}}</td>-->
                                                <td width="1%" class="text-right">
                                                    {{time_difference_from_time(appointment.totem_presence_arrival, false, true, appointment.room_presence_arrival) > 0 && appointment.totem_presence ? time_difference_from_time(appointment.totem_presence_arrival, true, true, appointment.room_presence_arrival) : '--:--'}}
                                                </td>
                                                <td width="1%" class="text-center">   
                                                    <div v-if="appointment.icon == 'therapyStart'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 640 512">
                                                            <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/>
                                                        </svg>                                                           
                                                    </div>                                                                                                         
                                                    <div v-else-if="appointment.icon == 'preparation'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 384 512">
                                                            <path d="M0 32C0 14.3 14.3 0 32 0H352c17.7 0 32 14.3 32 32V64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64V32zm32 96H352V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zM160 240v48H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V352h48c8.8 0 16-7.2 16-16V304c0-8.8-7.2-16-16-16H224V240c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
                                                        </svg>                                                      
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'totem'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto effetto-lampeggia-loop" viewBox="0 0 16 16">
                                                            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                        </svg>                                                      
                                                    </div>  
                                                    <div v-else-if="appointment.icon == 'closed'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                                            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                                        </svg>                               
                                                    </div>
                                                    <div v-else-if="appointment.icon == 'notShown'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512">
                                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"/>
                                                        </svg>                    
                                                    </div>  
                                                    <div v-else>
                                                        -
                                                    </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                        <!-- Rapporto -->
                        <vue-html2pdf

                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "rapporto"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2Pdf_rapporto"

                        >

                            <section slot="pdf-content" class="f-size_PDF">

                                <section v-for="(room, index) in roomsForPDF" :key="`room_rapporto_${index}`">

                                <!-- rooms[appointment.rooms.title].pages_appointments[length-1].appointments.push(appointment) -->

                                    <section class="f-family-monospace">

                                        <p class="text-uppercase font-weight-bold mb-2">
                                            STANZA: {{index}}
                                        </p>

                                        <section v-for="(page_appointment, index_2) of room.pages_appointments" :key="`room_${index}_page_${index_2}`">

                                            <table class="table table-striped table-bordered table-sm m-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Paziente</th>
                                                        <th scope="col">Data</th>
                                                        <th scope="col">Ora</th>
                                                        <th scope="col">Arrivo</th>
                                                        <th scope="col">Chiamata</th>
                                                        <th scope="col">Attesa appuntamento</th>
                                                        <th scope="col">Inizio pausa</th>
                                                        <th scope="col">Fine pausa</th>
                                                        <th scope="col">Termine</th>
                                                        <th scope="col">Durata</th>
                                                        <th scope="col">Attesa arrivo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="appointment of page_appointment.appointments" :key="`appointment_rapporto_${appointment.id}`">
                                                        <td class="text-truncate">
                                                            {{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.date | data_slash}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.h_start | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.totem_presence_arrival | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.room_presence_arrival | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{time_difference_from_time(
                                                                appointment.h_start,
                                                                false,
                                                                true,
                                                                appointment.room_presence_arrival
                                                            )}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.pause_start_time | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.pause_end_time | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-center">
                                                            {{appointment.appointment_closed ? appointment.appointment_closed.date : "" | data_ora}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{appointment.appointment_closed ? time_difference_from_time(
                                                                appointment.room_presence_arrival,
                                                                false,
                                                                true,
                                                                appointment.appointment_closed.date
                                                            ) : ""}}
                                                        </td>
                                                        <td width="1%" class="text-right">
                                                            {{time_difference_from_time(
                                                                appointment.totem_presence_arrival,
                                                                false,
                                                                true,
                                                                appointment.room_presence_arrival
                                                            )}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div v-if="index_2 == room.pages_appointments.length-1">
                                                <p class="text-right m-0 font-weight-bold">
                                                    Media attesa {{appointments_average_time(
                                                        get_appointments_from_paginations(
                                                            room.pages_appointments
                                                        )
                                                    ).waiting}}
                                                </p>
                                                <p class="text-right m-0 font-weight-bold">
                                                    Media visite {{appointments_average_time(
                                                        get_appointments_from_paginations(
                                                            room.pages_appointments
                                                        )
                                                    ).visit}}
                                                </p>
                                            </div>

                                            <div class="html2pdf__page-break" />
                                            
                                        </section>

                                    </section>
                                    
                                </section>

                            </section>

                        </vue-html2pdf>
                            
                        <!-- Scarico -->
                        <vue-html2pdf
                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "scarico"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2Pdf"

                        >

                            <section slot="pdf-content" class="container">

                                <div class="row mb-3">
                                    <div class="col-lg-6">
                                        <img src="../../../assets/ifo_logo_3.png" class="w-100">
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <p class="m-0">
                                            <small>
                                                Scarico giornaliero <b>Ematoga</b>
                                            </small>
                                        </p>
                                        <p class="mt-neg-5_5">
                                            <small class="text-monospace">
                                                {{pdf.today | data_slash}}
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <p>
                                    <small>
                                        Dalla data <b>{{filters.date.from | data_slash}}</b> alla data <b>{{filters.date.to | data_slash}}</b>
                                    </small>
                                </p>

                                <section class="mb-3 pdf-item" v-for="(room, index) in roomForStadardView" :key="`room_${index}`">

                                    <p class="font-weight-bold m-0">
                                        <span class="text-uppercase" :style="`color: #5c1b24`">STANZA: {{index}}</span>
                                    </p>

                                    <div class="alert alert-danger m-0" v-if="room.appointments.length < 1">
                                        Nessun appuntamento
                                    </div>

                                    <div v-else>

                                        <div class="container-fluid">

                                            <section class="row pdf-item">
                                                <div class="col-lg-6 col-xl-2 text-truncate pl-1">
                                                    <small>
                                                        <strong>Data</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <strong>Paziente</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <strong>Patologia</strong>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-2 text-truncate pr-0">
                                                    <small>
                                                        <strong>Terapia</strong>
                                                    </small>
                                                </div>
                                            </section>

                                            <section class="row border-bottom pdf-item" v-for="appointment of room.appointments" :key="`appointment_${appointment.id}`" v-bind:class="{'bg-1303fc': appointment.totem_presence, 'bg-fc03b1': appointment.room_presence, 'bg-03fc1c': appointment.appointment_closed}">
                                                
                                                <div class="col-lg-6 col-xl-2 text-truncate pl-1">
                                                    <small class="f-family-monospace">
                                                        {{appointment.date | data_slash}} {{appointment.h_start | data_ora}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        {{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-4 text-truncate">
                                                    <small>
                                                        <span class="f-family-monospace font-weight-bold">{{appointment.patient_pathology.pathology.id_pathology}}</span> {{appointment.patient_pathology.pathology.title}}
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 col-xl-2 text-truncate pr-0">
                                                    <small>
                                                        {{appointment.patient_pathology.id_therapy ? appointment.patient_pathology.therapy.title : `-`}}
                                                    </small>
                                                </div>

                                            </section>

                                        </div>

                                    </div>

                                </section>

                            </section>

                        </vue-html2pdf>

                    </div>


                    <div v-if="view.mode == `pharmacy` && list_pharma == 1">
                        <vue-html2pdf
                            :show-layout                 = "false"
                            :enable-download             = "false"
                            :preview-modal               = "true"
                            :paginate-elements-by-height = "2480"
                            filename                     = "pharma"
                            :pdf-quality                 = "2"
                            :manual-pagination           = "false"

                            pdf-format        = "a4"
                            pdf-orientation   = "landscape"
                            pdf-content-width = "100%"

                            :html-to-pdf-options = "pdf.config_rapporto"
                            ref = "html2PdfPharma"

                        >

                            <section slot="pdf-content" class="container">

                                <div class="row mb-3">
                                    <div class="col-lg-6">
                                        <img src="../../../assets/ifo_logo_3.png" class="w-100">
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <p class="m-0">
                                            <small>
                                                Lista farmacia <b>Ematoga</b> 
                                            </small>
                                        </p>
                                        <p class="mt-neg-5_5">
                                            <small class="text-monospace">
                                                {{pdf.today | data_slash}}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                    
                                <div class="card card-body mb-3" v-for="(chairs, index) in roomForPharma" :key="`chairs_${index}`" v-show="!loaders.reading.appointments">
                                    <div class="row font-weight-bold mb-2 text-center">
                                        <div class="col-12">
                                            <span class="text-uppercase">
                                                Lista farmacia del {{ moment().format("DD/MM/YYYY") }}
                                            </span>
                                        </div>
                                    </div> 
                                    <div v-for="(chair, index) in chairs.chairs" :key="`chair_${index}`">

                                        <div v-for="(chair_detail, index) in chair" :key="`chair_detail_${index}`" class="mb-4">   
                                    

                                            <div class="row font-weight-bold mb-2">
                                                <div class="col-6">
                                                    <span class="text-uppercase">
                                                        {{ chair_detail.title }} {{ chair_detail.number }} 
                                                    </span>
                                                </div>
                                                <div class="col-6 text-right f-family-monospace">
                                                    <span class="f-size-1-em">
                                                        Totali: {{chair_detail.appointments.length}}
                                                    </span>
                                                </div>
                                            </div>  
                                                             
                                            <div class="alert alert-danger m-0" v-if="chair_detail.appointments.length < 1">
                                                Nessun farmaco
                                            </div>

                                            <div v-else>

                                                <div class="table-responsive m-0">
                                                    <table class="table table-sm table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col" @click="
                                                                    makeOrder(
                                                                        'h_start'
                                                                    )
                                                                ">
                                                                    <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                    </svg>
                                                                    <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                    </svg>
                                                                    <span class="ml-1">
                                                                        Ora
                                                                    </span>
                                                                </th>
                                                                <th scope="col" @click="
                                                                    makeOrder(
                                                                        'patient_pathology.patient.surname'
                                                                    )
                                                                ">
                                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                    </svg>
                                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                    </svg>
                                                                    <span class="ml-1">
                                                                        Soggetto
                                                                    </span>
                                                                </th>
                                                                <th scope="col">Patologia</th>
                                                                <th scope="col">Terapia</th>
                                                                <th scope="col">Stato</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(appointment, index) of chair_detail.appointments" :key="`appointment_${appointment.id}`" id="tooltip-target-1" @click="updateDayOfAppointmentTherapies(appointment)">
                                                                <th width="1%" scope="row">{{index + 1}}</th>
                                                                <td width="2%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                                <td width="20%">{{appointment.appointment.patient_pathology.patient.surname}} {{appointment.appointment.patient_pathology.patient.name}}</td>
                                                                <td width="35%">{{appointment.appointment.patient_pathology.pathology.id_pathology}} {{appointment.appointment.patient_pathology.pathology.title}}</td>
                                                                <td width="25%">{{appointment.appointment.patient_pathology.id_therapy ? appointment.appointment.patient_pathology.therapy.title : "-"}}
                                                                    <b-tooltip target="tooltip-target-1" triggers="hover">Note per il farmacista: {{appointment.preparation_pharma_description}}</b-tooltip>
                                                                </td>
                                                                <td width="17%">
                                                                    <div v-if="!appointment.medicine_ready_start_time && appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                            <strong>PRONTO</strong>
                                                                    </div>
                                                                    <div v-else-if="!appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                        <strong>IN PREPARAZIONE</strong>
                                                                    </div> 
                                                                    <div v-else-if="appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && !appointment.preparation_pharma_start_time && !appointment.taking_charge_start_time">
                                                                        <strong>FARMACO GIA' PRESENTE IN REPARTO</strong>
                                                                    </div>                                                              
                                                                    <div v-else>
                                                                        <strong>PRESA IN CARICO</strong>
                                                                    </div>                                                                                                                                                          
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>    
                                    </div>    
                                    <div v-for="(room, index) in chairs.rooms" :key="`room_${index}`">

                                        <div v-for="(room_detail, index) in room" :key="`room_detail_${index}`" class="mb-4">                             
                                            <div class="row font-weight-bold mb-2">
                                                <div class="col-6">
                                                    <span class="text-uppercase">
                                                        STANZA: {{ room_detail.title }} 
                                                    </span>
                                                </div>
                                                <div class="col-6 text-right f-family-monospace">
                                                    <span class="f-size-1-em">
                                                        Totali: {{room_detail.appointments.length}}
                                                    </span>
                                                </div>
                                            </div>  
                                                                
                                            <div class="alert alert-danger m-0" v-if="room_detail.appointments.length < 1">
                                                Nessun farmaco
                                            </div>

                                            <div v-else>

                                                <div class="table-responsive m-0">
                                                    <table class="table table-sm table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col" @click="
                                                                    makeOrder(
                                                                        'h_start'
                                                                    )
                                                                ">
                                                                    <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                    </svg>
                                                                    <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                    </svg>
                                                                    <span class="ml-1">
                                                                        Ora
                                                                    </span>
                                                                </th>
                                                                <th scope="col" @click="
                                                                    makeOrder(
                                                                        'patient_pathology.patient.surname'
                                                                    )
                                                                ">
                                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                    </svg>
                                                                    <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                    </svg>
                                                                    <span class="ml-1">
                                                                        Soggetto
                                                                    </span>
                                                                </th>
                                                                <th scope="col">Patologia</th>
                                                                <th scope="col">Terapia</th>
                                                                <th scope="col">Stato</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(appointment, index) of room_detail.appointments" :key="`appointment_${appointment.id}`" id="tooltip-target-1" @click="updateDayOfAppointment(appointment)">
                                                                <th width="1%" scope="row">{{index + 1}}</th>
                                                                <td width="2%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                                <td width="20%">{{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}</td>
                                                                <td width="35%">{{appointment.patient_pathology.pathology.id_pathology}} {{appointment.patient_pathology.pathology.title}}</td>
                                                                <td width="25%">{{appointment.patient_pathology.id_therapy ? appointment.patient_pathology.therapy.title : "-"}}
                                                                    <b-tooltip target="tooltip-target-1" triggers="hover">Note per il farmacista: {{appointment.preparation_pharma_description}}</b-tooltip>
                                                                </td>
                                                                <td width="17%">
                                                                    <div v-if="!appointment.medicine_ready_start_time && appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                            <strong>PRONTO</strong>
                                                                    </div>
                                                                    <div v-else-if="!appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                        <strong>IN PREPARAZIONE</strong>
                                                                    </div> 
                                                                    <div v-else-if="appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && !appointment.preparation_pharma_start_time && !appointment.taking_charge_start_time">
                                                                        <strong>FARMACO GIA' PRESENTE IN REPARTO</strong>
                                                                    </div>                                                              
                                                                    <div v-else>
                                                                        <strong>PRESA IN CARICO</strong>
                                                                    </div>                                                                                                                                                          
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>    

                                    </div> 

                                </div>


                            </section>

                        </vue-html2pdf>

                        <div v-if="!this.functionSpecialEnabled(16)"> 
                            <div class="alert alert-danger" >
                                Non sei autorizzato a visualizzare questa pagina
                            </div>
                        </div>
                        
                        <div v-else>   
                            
                            <div class="alert alert-danger" v-if="Object.keys(roomForPharma).length < 1 && !loaders.reading.appointments">
                                Nessun farmaco
                            </div>
                            <div class="alert alert-info" v-show="loaders.reading.appointments">
                                Ricerca farmaci da preparare..
                            </div>

                            <div class="card card-body mb-3" v-for="(chairs, index) in roomForPharma" :key="`chairs_${index}`" v-show="!loaders.reading.appointments">
                                <div class="row font-weight-bold mb-2 text-center">
                                    <div class="col-12">
                                        <span class="text-uppercase">
                                            Lista farmacia del {{ moment().format("DD/MM/YYYY") }}
                                        </span>
                                    </div>
                                </div> 
                                <div v-for="(chair, index) in chairs.chairs" :key="`chair_${index}`">
                                    <div v-for="(chair_detail, index) in chair" :key="`chair_detail_${index}`" class="mb-4">                             
                                        <div class="row font-weight-bold mb-2">
                                            <div class="col-6">
                                                <span class="text-uppercase">
                                                    {{ chair_detail.title }} {{ chair_detail.number }} 
                                                </span>
                                            </div>
                                            <div class="col-6 text-right f-family-monospace">
                                                <span class="f-size-1-em">
                                                    Totali: {{chair_detail.appointments.length}}
                                                </span>
                                            </div>
                                        </div>  
                                                             
                                        <div class="alert alert-danger m-0" v-if="chair_detail.appointments.length < 1">
                                            Nessun farmaco
                                        </div>

                                        <div v-else>

                                            <div class="table-responsive m-0">
                                                <table class="table table-sm table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col" @click="
                                                                makeOrder(
                                                                    'h_start'
                                                                )
                                                            ">
                                                                <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                </svg>
                                                                <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                </svg>
                                                                <span class="ml-1">
                                                                    Ora
                                                                </span>
                                                            </th>
                                                            <th scope="col" @click="
                                                                makeOrder(
                                                                    'patient_pathology.patient.surname'
                                                                )
                                                            ">
                                                                <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                </svg>
                                                                <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                </svg>
                                                                <span class="ml-1">
                                                                    Soggetto
                                                                </span>
                                                            </th>
                                                            <th scope="col">Patologia</th>
                                                            <th scope="col">Terapia</th>
                                                            <th scope="col">Stato</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(appointment, index) of chair_detail.appointments" :key="`appointment_${appointment.id}`" id="tooltip-target-1" @click="updateDayOfAppointmentTherapies(appointment)">
                                                            <th width="1%" scope="row">{{index + 1}}</th>
                                                            <td width="2%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                            <td width="20%">{{appointment.appointment.patient_pathology.patient.surname}} {{appointment.appointment.patient_pathology.patient.name}}</td>
                                                            <td width="35%">{{appointment.appointment.patient_pathology.pathology.id_pathology}} {{appointment.appointment.patient_pathology.pathology.title}}</td>
                                                            <td width="25%">{{appointment.appointment.patient_pathology.id_therapy ? appointment.appointment.patient_pathology.therapy.title : "-"}}
                                                                <b-tooltip target="tooltip-target-1" triggers="hover">Note per il farmacista: {{appointment.preparation_pharma_description}}</b-tooltip>
                                                            </td>
                                                            <td width="17%">
                                                                <div v-if="!appointment.medicine_ready_start_time && appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                        <strong>PRONTO</strong>
                                                                </div>
                                                                <div v-else-if="!appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                    <strong>IN PREPARAZIONE</strong>
                                                                </div> 
                                                                <div v-else-if="appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && !appointment.preparation_pharma_start_time && !appointment.taking_charge_start_time">
                                                                    <strong>FARMACO GIA' PRESENTE IN REPARTO</strong>
                                                                </div>                                                              
                                                                <div v-else>
                                                                    <strong>PRESA IN CARICO</strong>
                                                                </div>                                                                                                                                                          
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>    
                                </div>    
                                <div v-for="(room, index) in chairs.rooms" :key="`room_${index}`">

                                    <div v-for="(room_detail, index) in room" :key="`room_detail_${index}`" class="mb-4">                             
                                        <div class="row font-weight-bold mb-2">
                                            <div class="col-6">
                                                <span class="text-uppercase">
                                                    STANZA: {{ room_detail.title }} 
                                                </span>
                                            </div>
                                            <div class="col-6 text-right f-family-monospace">
                                                <span class="f-size-1-em">
                                                    Totali: {{room_detail.appointments.length}}
                                                </span>
                                            </div>
                                        </div>  
                                                            
                                        <div class="alert alert-danger m-0" v-if="room_detail.appointments.length < 1">
                                            Nessun farmaco
                                        </div>

                                        <div v-else>

                                            <div class="table-responsive m-0">
                                                <table class="table table-sm table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col" @click="
                                                                makeOrder(
                                                                    'h_start'
                                                                )
                                                            ">
                                                                <svg v-if="ordinamento['h_start'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                </svg>
                                                                <svg v-if="ordinamento['h_start'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                </svg>
                                                                <span class="ml-1">
                                                                    Ora
                                                                </span>
                                                            </th>
                                                            <th scope="col" @click="
                                                                makeOrder(
                                                                    'patient_pathology.patient.surname'
                                                                )
                                                            ">
                                                                <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                                                </svg>
                                                                <svg v-if="ordinamento['patient_pathology.patient.surname'] == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                                                </svg>
                                                                <span class="ml-1">
                                                                    Soggetto
                                                                </span>
                                                            </th>
                                                            <th scope="col">Patologia</th>
                                                            <th scope="col">Terapia</th>
                                                            <th scope="col">Stato</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(appointment, index) of room_detail.appointments" :key="`appointment_${appointment.id}`" id="tooltip-target-1" @click="updateDayOfAppointment(appointment)">
                                                            <th width="1%" scope="row">{{index + 1}}</th>
                                                            <td width="2%" class="text-center">{{appointment.h_start | data_ora}}</td>
                                                            <td width="20%">{{appointment.patient_pathology.patient.surname}} {{appointment.patient_pathology.patient.name}}</td>
                                                            <td width="35%">{{appointment.patient_pathology.pathology.id_pathology}} {{appointment.patient_pathology.pathology.title}}</td>
                                                            <td width="25%">{{appointment.patient_pathology.id_therapy ? appointment.patient_pathology.therapy.title : "-"}}
                                                                <b-tooltip target="tooltip-target-1" triggers="hover">Note per il farmacista: {{appointment.preparation_pharma_description}}</b-tooltip>
                                                            </td>
                                                            <td width="17%">
                                                                <div v-if="!appointment.medicine_ready_start_time && appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                        <strong>PRONTO</strong>
                                                                </div>
                                                                <div v-else-if="!appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time">
                                                                    <strong>IN PREPARAZIONE</strong>
                                                                </div> 
                                                                <div v-else-if="appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && !appointment.preparation_pharma_start_time && !appointment.taking_charge_start_time">
                                                                    <strong>FARMACO GIA' PRESENTE IN REPARTO</strong>
                                                                </div>                                                              
                                                                <div v-else>
                                                                    <strong>PRESA IN CARICO</strong>
                                                                </div>                                                                                                                                                          
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>    
                                </div> 
                            </div>
                        </div>     
                    </div>  
                                     

                </div>            

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item" v-if="list_pharma == 0">
                        <router-link class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" to="/user/agenda/aggiungi" v-bind:class="{'disabled': functionDisabled(1, 'create')}">
                            <span class="d-none d-md-block">Nuovo appuntamento</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="list_pharma == 0">
                        <router-link class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" to="/user/agenda/aggiungi_terapia" v-bind:class="{'disabled': functionDisabled(1, 'create')}">
                            <span class="d-none d-md-block">Nuova terapia</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li> 
                    <li class="nav-item" v-if="list_pharma == 0">
                        <router-link class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" to="/user/agenda/aggiungi_prelievo" v-bind:class="{'disabled': functionDisabled(1, 'create')}">
                            <span class="d-none d-md-block">Nuovo prelievo</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>                                         
  
                    <li class="nav-item" v-if="view.mode == `standard` || view.mode == `pharmacy` || view.mode == `chairs` || view.mode == `blood_tests`">
                        <a href="/user/agenda?p=c" @click="changeModeView" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Calendar view</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                            </svg>
                        </a>      
                    </li>                        
                    <li class="nav-item" v-if="view.mode == `calendar` || view.mode == `pharmacy` || view.mode == `chairs` || view.mode == `blood_tests`">
                        <a href="/user/agenda?p=s" @click="changeModeView" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Standard view</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-view-stacked ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"/>
                            </svg>
                        </a>      
                    </li>                                      
                    <li class="nav-item" v-if="view.mode != `pharmacy` && list_pharma == 0">
                        <a href="/user/agenda?p=f" @click="changeModeView" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Lista farmacia</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capsule-pill ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M11.02 5.364a3 3 0 0 0-4.242-4.243L1.121 6.778a3 3 0 1 0 4.243 4.243l5.657-5.657Zm-6.413-.657 2.878-2.879a2 2 0 1 1 2.829 2.829L7.435 7.536 4.607 4.707ZM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm-.5 1.042a3 3 0 0 0 0 5.917V9.042Zm1 5.917a3 3 0 0 0 0-5.917v5.917Z"/>
                            </svg>                           
                        </a>
                    </li>  
                    <li class="nav-item" v-if="view.mode == `pharmacy` && list_pharma == 1 && this.functionSpecialEnabled(16)">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" @click="createPDF('html2PdfPharma')">
                            
                            <span class="d-none d-md-block">Stampa lista</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                            </svg>

                        </a>
                    </li>                                     
                    <li class="nav-item" v-show="view.mode == `standard` && list_pharma == 0">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" @click="createPDF('html2Pdf')">
                            
                            <span class="d-none d-md-block">Scarico</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                            </svg>

                        </a>
                    </li>
                    <!--<li class="nav-item" v-show="view.mode == `calendar`">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" @click="changeWeekDayShow">
                            <span class="d-none d-md-block" v-if="!calendar.flags.hideWeekDays">Settimana intera</span>
                            <span class="d-none d-md-block" v-else>Settimana lavorativa</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar4-week ml-1 mt-1" viewBox="0 0 16 16">
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"/>
                                <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                            </svg>
                        </a>
                    </li>-->
                    <li class="nav-item" v-show="view.mode == `standard` && list_pharma == 0">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" @click="createPDF('html2Pdf_rapporto')">
                            <span class="d-none d-md-block">Rapporto tempi</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                            </svg>
                        </a>
                    </li>
                    <!--<li class="nav-item" v-if="functionSpecialEnabled(5)" v-show="view.mode == `standard`">
                        <export-excel class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom cursor-pointer" :data="data_for_excel" :fields="excel.fields" :worksheet="'RAPPORTO'" :name="`rapporto${new Date() | data_underscore}`">
                            <span class="d-none d-md-block">Rapporto</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                            </svg>
                        </export-excel>
                    </li>-->
                    <li class="nav-item" v-if="view.mode != `chairs`">
                        <a href="/user/agenda?p=h" @click="changeModeView" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Disponibilità poltrone</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ml-md-2 mt-md-1" viewBox="0 0 640 512">
                                <path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/>
                            </svg>                                                     
                        </a>
                    </li> 
                    <li class="nav-item" v-if="view.mode != `blood_tests`">
                        <a href="/user/agenda?p=b" @click="changeModeView" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Sala prelievo</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ml-md-2 mt-md-1" viewBox="0 0 512 512">
                                <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                            </svg>                                                                                
                        </a>
                    </li>                                           
                </ul>
            </div>
        </div>

    </div>
</template>

<script>

    import moment      from 'moment'
    import $           from 'jquery'
    import VueCal      from 'vue-cal'
    import store       from '@/store'
    import VueHtml2pdf from 'vue-html2pdf'
    import _           from 'lodash'
    import doughnut    from '@/components/doughnut.vue'

    import 'vue-cal/dist/i18n/it.js'
    import 'vue-cal/dist/vuecal.css'
    //import Swal from 'sweetalert2'

    import {
        orderBy,
        filter
    } from 'lodash'
    
    const today = new Date()  
    var arr = new Array();  
    var objroom = new Array(); 
    var list2 = new Object(); 
    var list = new Array();     
    var availibilities = [];
    var newObj = {}; 
    var days_holiday_obj = {}
    var list2Holiday = new Object(); 
    var listHoliday = new Array();        
    
    export default {
        
        data() {
            return {
                yearCal: new Date().getFullYear(),
                readAppToday: 0,
                classCal: 1, 
                interval1: null,
                interval2: null,
                list_pharma: 0,
                flag: 0,
                
                rooms: {
                    DB: []
                },
                room: {
                    DB: []
                },                
                pathologies: {
                    DB: []
                },
                therapies: {
                    DB: []
                },
                diagnoses: {
                    DB: []
                },
                loaders: {
                    reading: {
                        appointments: true,
                        appointments_chairs: true,
                        appointments_blood_test: true
                    }
                },
                calendar: {
                    config: {
                        activeView:   "day",
                        minDate:      today,
                        timeStep:     20,
                        roomSelected: {},
                        events:       [],
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        selectedDate: today,
                        hideWeekDays: [6,7]
                    },
                    info: {
                        actual_date: new Date()
                    },
                    flags: {
                        hideWeekDays: false
                    }
                },
                filters: {
                    rooms:       {},
                    pathologies: {},
                    therapies:   {},
                    therapies_today: 0,
                    date: {
                        from: null,
                        to:   null
                    },
                    pathology_input_search: ""
                },
                counters: {
                    appointments: {
                        today: 0
                    }
                },
                view: {
                    mode: `calendar`
                },
                patient_waiting_nurses: 0,
                patient_waiting_therapy: 0,
                pharma_waiting_therapy: 0,
                patient_waiting_take_charge: 0, 
                patient_waiting_verify: 0,               
                pdf: {
                    config: {
                        margin: [5, 5, 5, 5]
                    },
                    config_rapporto: {

                        margin: 10,

                        jsPDF: {
                            //unit: 'in',
                            format: 'a4',
                            orientation: 'landscape'
                        },

                        filename: "rapporto"

                    },
                    config_pharma: {

                        margin: 10,

                        jsPDF: {
                            //unit: 'in',
                            format: 'a4',
                            orientation: 'landscape'
                        },

                        filename: "listafarmacia"

                    },                   
                    today
                },
                DB: {
                    appointments:               [],
                    appointments_chairs:        [],
                    appointments_blood_tests:    []
                },
                charts: {
                    doughnut: {
                        options: {

                            responsive:          true, 
                            maintainAspectRatio: false,
                            cutoutPercentage:    80,

                            animation: {
                                animateRotate: true
                            },
                            legend: {
                                display: false
                            }

                        },
                        data: {
                            labels: [
                                "Nessuno stato",
                                "In sala d'aspetto",
                                "In visita",
                                "Terminata",
                                "In pausa"
                            ],
                            datasets: [
                                {
                                    backgroundColor: [
                                        "gray",
                                        "#ff9900",
                                        "#9a0e00",
                                        "#008000",
                                        "#bc7872"
                                    ],
                                    data: [
                                        this.count_appointments_nessunostato,
                                        this.count_appointments_insaladiaspetto,
                                        this.count_appointments_invisita,
                                        this.count_appointments_visitacompletata,
                                        this.count_appointments_pausa
                                    ]
                                }
                            ]
                        }
                    }
                },
                ordinamento: {
                    h_start:    "asc",
                    ["patient_pathology.patient.surname"]: null,
                    soggetto:   null,
                    patologia:  null,
                    terapia:    null,
                    attesa:     null
                }

            }
        },
        components: {
            VueCal,
            VueHtml2pdf,
            doughnut
        },
        methods: {
            search_appointments_therapies_today(){
                if (this.filters.therapies_today) this.filters.therapies_today = 1;
                else this.filters.therapies_today = 0;
                this.search_appointments_by_filters_of_calendar_view()
            },
            listPharma(){
                this.list_pharma = 1
                this.view.mode = "pharmacy"
            },
            onChangeProcessed(e, g) {
                if (e.target.checked == true) {
                    this.readRoom(g);     
                }else {
                    this.readRoom(0);
                } 
            },    
            easterDateLLongre(Y) {
                let M=3, G= Y % 19+1, C= ~~(Y/100)+1, L=~~((3*C)/4)-12,
                E=(11*G+20+ ~~((8*C+5)/25)-5-L)%30, D;
                E<0 && (E+=30);
                (E==25 && G>11 || E==24) && E++;
                (D=44-E)<21 && (D+=30);
                (D+=7-(~~((5*Y)/4)-L-10+D)%7)>31 && (D-=31,M=4);

                if(D == 31){
                    D = 1;
                    M = M+1
                } else {
                    D = D+1;
                }  

                if(M < '10'){ 
                    M = '0' + M;
                } 
  
                if(D < '10'){ 
                    D = '0' + D;
                }                 
                return Y + '-' + M + '-' + D;
            }, 
            format_room_for_calendar_holidays(arrHoliday) {
                listHoliday = []
                for (let index = 0; index < arrHoliday.length; ++index) {
                    const element = Object.keys(arrHoliday[index]);
                    
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();
                    listHoliday.push({
                        [day] : {
                            date: element,                       
                            from: {'am': 8 * 60, 'pm': 13 * 60},
                            to: {'am': 13 * 60, 'pm': 20 * 60},
                            class: 'doctor-3',
                            label: 'Festivo'                               
                        }                                     
                    });                                                                   
                }  
          
                list2Holiday = Object.assign({}, listHoliday);  
                return list2Holiday;
                      
            },                                          
            format_room_for_calendar(room) {
                arr = [];
                objroom.push(room);

                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  

                var data;
                var from = '00:00';
                var to = '00:00';
                var frompm = '00:00';
                var topm = '00:00';               
                for (let index = 0; index < objroom.length; ++index) {
                    startDate = new Date(objroom[index].dal);
                    endDate = new Date(objroom[index].al);
                  
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dateFormat = new Date(format4);
                        var dayweek = dateFormat.getDay();

                        if(dayweek == '1'){
                            from = objroom[index].monday_1.am.dal;
                            to = objroom[index].monday_1.am.al;
                            frompm = objroom[index].monday_1.pm.dal;
                            topm = objroom[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroom[index].tuesday_2.am.dal;
                            to = objroom[index].tuesday_2.am.al;       
                            frompm = objroom[index].tuesday_2.pm.dal;
                            topm = objroom[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroom[index].wednesday_3.am.dal;
                            to = objroom[index].wednesday_3.am.al;     
                            frompm = objroom[index].wednesday_3.pm.dal;
                            topm = objroom[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroom[index].thursday_4.am.dal;
                            to = objroom[index].thursday_4.am.al; 
                            frompm = objroom[index].thursday_4.pm.dal;
                            topm = objroom[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroom[index].friday_5.am.dal;
                            to = objroom[index].friday_5.am.al;         
                            frompm = objroom[index].friday_5.pm.dal;
                            topm = objroom[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            
                            from = objroom[index].saturday_6.am.dal;
                            to = objroom[index].saturday_6.am.al;   
                            frompm = objroom[index].saturday_6.pm.dal;
                            topm = objroom[index].saturday_6.pm.al; 
                                    
                        } 
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arr.push(data);                        
                    }                                                 
                }   
                
                for (let index = 0; index < arr.length; ++index) {
                    const element = Object.keys(arr[index]);
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();

                    
                    var fromMor = String(arr[index][element].am.from);                   
                    var toMor = String(arr[index][element].am.to);

                    var fromMorS = fromMor.split(':');                  
                    var fromMorH = fromMorS[0];                    
                    var fromMorM = fromMorS[1];
                    var minutesMor = 0;

                    if(fromMorM != '00'){
                        if(fromMorM >= '10' && fromMorM <= '25'){ 
                            minutesMor = '2';
                        } else if(fromMorM >= '26' && fromMorM <= '35'){
                            minutesMor = '5';
                        } else if(fromMorM >= '36' && fromMorM <= '59'){
                            minutesMor = '7';
                        }     
                    } 
                    var fromMorF = parseInt(fromMorH) + '.'+ minutesMor;

                    var toMorS = toMor.split(':');                  
                    var toMorH = toMorS[0];                    
                    var toMorM = toMorS[1];
                    var tominutesMor = 0;

                    if(toMorM != '00'){
                        if(toMorM >= '10' && toMorM <= '25'){ 
                            tominutesMor = '2';
                        } else if(toMorM >= '26' && toMorM <= '35'){
                            tominutesMor = '5';
                        } else if(toMorM >= '36' && toMorM <= '59'){
                            tominutesMor = '7';
                        }     
                    } 

                    var toMorF = parseInt(toMorH) + '.'+ tominutesMor;   

                    //afternoon
                    var fromAft = String(arr[index][element].pm.from);                   
                    var toAft = String(arr[index][element].pm.to);

                    var fromAftS = fromAft.split(':');                  
                    var fromAftH = fromAftS[0];                    
                    var fromAftM = fromAftS[1];
                    var minutesAft = 0;

                    if(fromAftM != '00'){
                        if(fromAftM >= '10' && fromAftM <= '25'){ 
                            minutesAft = '2';
                        } else if(fromAftM >= '26' && fromAftM <= '35'){
                            minutesAft = '5';
                        } else if(fromAftM >= '36' && fromAftM <= '59'){
                            minutesAft = '7';
                        }     
                    } 
                    var fromAftF = (parseInt(fromAftH) + '.'+ minutesAft);

                    

                    var toAftS = toAft.split(':');   
             
                    var toAftH = toAftS[0];                    
                    var toAftM = toAftS[1];
                    var tominutesAft = 0;

                    if(toAftM != '00'){
                        if(toAftM >= '10' && toAftM <= '25'){ 
                            tominutesAft = '2';
                        } else if(toAftM >= '26' && toAftM <= '35'){
                            tominutesAft = '5';
                        } else if(toAftM >= '36' && toAftM <= '59'){
                            tominutesAft = '7';
                        }     
                    } 

                    var toAftF = parseInt(toAftH) + '.'+ tominutesAft;   

                    if(day == 0){
                        list.push({
                            [7] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                              
                            }                                                                 
                        }); 
                    } 
                    if(day == 6){
                        list.push({
                            [6] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                              
                            }                                                                 
                        }); 
                    }                       

                    let year = this.yearCal

                    let easter = this.easterDateLLongre(year);

                    var days_holiday = [year + "-01-01", year + "-01-06", year + "-04-25", year + "-05-01", year + "-06-02", year + "-06-29", year + "-08-15", year + "-11-01", year + "-12-08", year + "-12-25", year + "-12-26", easter];
                    
                    let dayHol = ('0' + dateNew.getDate()).slice(-2);       
                    let monthHol = ('0' + (dateNew.getMonth()+1)).slice(-2);        
                    let yearHol = dateNew.getFullYear();  
                    let format4Hol = yearHol + "-" + monthHol + "-" + dayHol;

                    if(days_holiday.includes(format4Hol)){
                        list.push({
                            [day] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                               
                            }                                     
                        }); 
                    } else {
                        if(fromMorH != '' || fromAftH != ''){               
                            list.push({
                                [day] : {
                                    date: element,                       
                                    from: {'am': fromMorF * 60, 'pm': fromAftF * 60},
                                    to: {'am': toMorF * 60, 'pm': toAftF * 60},
                                    class: 'doctor-1',
                                    label: 'Disponibile'                               
                                }                                     
                            }); 
                        } 
                    }                                        

                                                            
                }  

                
                list2 = Object.assign({}, list);  
                return list2;
                      
            },                  
            change_calendar(event) {

                // Ricaviamo la tipologia di vista
                const {
                    view,
                    startDate
                } = event

                // Instanziamo l'oggetto query da inserire nell'url
                let query_for_url = {}

                // Esaminiamo i casi
                switch(view) {
                    case "day":
                        query_for_url.v = "d"
                        query_for_url.d = moment(startDate).format("YYYY-MM-DD")
                    break
                    case "week":
                        query_for_url.v = "w"
                        query_for_url.d = moment(moment(startDate).format("YYYY-MM-DD 05:00")).weekday(moment().weekday()).format("YYYY-MM-DD")
                    break
                    case "month":
                        query_for_url.v = "m"
                        query_for_url.d = moment().format(`YYYY-${moment(startDate).format("MM")}-DD`)
                    break
                    case "year":
                        query_for_url.v = "y"
                        query_for_url.d = moment().format(`${moment(startDate).format("YYYY")}-MM-DD`)
                    break
                }

                // Controllo per evitare errore in console
                if (!_.isEqual(this.$route.query, query_for_url)) {

                    // Impostiamo nel calendario le date di nostro interesse e il tipo di visualizzazione
                    //if (this.$route.path !== path) this.$router.push(path)      
                    //console.log(this.$route.query)             
                    this.$router.replace({ name: "user_appointments_index", query: {
                        ...this.$route.query,
                        ...query_for_url
                    }}).catch(()=>{});
                    
                    // Richiamo funzione per la ricerca degli appuntamenti
                    this.search_appointments_by_url_params()
                }

            },
            ready_calendar() {
                this.search_appointments_by_url_params()
            },
            search_appointments_by_url_params() {

                // Recuperiamo la tipologia di vista e giorno
                const {
                    v, // view
                    d,  // day
                    p, // prospective
                } = this.$route.query     
                
                
                const currentDate = new Date().toJSON().slice(0, 10);
                
                if(this.check_presence_of_wrong_url_params) {
                    
                    if(p == "s" && this.readAppToday == 0) {   
                        this.readAppointments(                        
                            moment(currentDate).format("YYYY-MM-DD 00:00"),
                            moment(currentDate).format("YYYY-MM-DD 23:59")

                            //moment().weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                            //moment().weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")                        
                        )
                    } else {
                        
                        this.readAppointments(
                            moment(currentDate).format("YYYY-MM-DD 00:00"),
                            moment(currentDate).format("YYYY-MM-DD 23:59")
                            /*moment().weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                            moment().weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")*/
                        )
                    }  

                } else {

                    // Visualizziamo il giorno
                    this.calendar.config.selectedDate = d

                    switch(v) {

                        case "d":

                            // Impostiamo la visibilità a giorno
                            this.calendar.config.activeView = "day"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).format("YYYY-MM-DD 00:00"),
                                moment(d).format("YYYY-MM-DD 23:59")
                            )

                        break
                        case "w":

                            // Impostiamo la visibilità a mese
                            this.calendar.config.activeView = "week"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                                moment(d).weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")
                            )

                        break
                        case "m":

                            // Impostiamo la visibilità a mese
                            this.calendar.config.activeView = "month"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).format(`YYYY-MM-01 00:00`),
                                moment(d).endOf('month').format('YYYY-MM-DD 23:59')
                            )

                        break
                        case "y":

                            // Impostiamo la visibilità ad anno
                            this.calendar.config.activeView = "year"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).format(`YYYY-01-01 00:00`),
                                moment(d).endOf('year').format('YYYY-MM-DD 23:59')
                            )

                        break

                    }

                }
            },
            search_appointments_by_filters_of_standard_view() {
               
                this.readAppointments(
                    
                    moment(this.filters.date.from).format("YYYY-MM-DD 00:00"),
                    moment(this.filters.date.to).format("YYYY-MM-DD 23:59")
                )
            },
            search_appointments_by_filters_of_chairs_view() {
               
               this.readAppointmentsByChairs(
                   
                   moment(this.filters.date.from).format("YYYY-MM-DD 00:00"),
                   moment(this.filters.date.to).format("YYYY-MM-DD 23:59"),
               )
            },   
            search_appointments_by_filters_of_blood_test_view() {
               
               this.readAppointmentsByBloodTest(
                   
                   moment(this.filters.date.from).format("YYYY-MM-DD 00:00"),
                   moment(this.filters.date.to).format("YYYY-MM-DD 23:59"),
               )
            },                       
            search_appointments_by_filters_of_calendar_view() {
                this.manage_url_params_filter()
                this.search_appointments_by_url_params()
            },
            manage_url_params_filter() {

                let valori = ""

                // Iteriamo il data con il filtro stanze
                for(const filter in this.filters.rooms) {

                    if(this.filters.rooms[filter]) valori += `${filter},`

                }

                this.$router.replace({
                    name: "user_appointments_index",
                    query: {...this.$route.query, r: valori}
                }).catch(()=>{});

                // Se valore true facciamo update nell'URL

                // Se false lo eliminiamo
                
            },
            set_params_filter_input_search_of_standard_view(from_date, to_date) {
                this.filters.date.from = moment(from_date).format("YYYY-MM-DD")
                this.filters.date.to   = moment(to_date).format("YYYY-MM-DD")
            },
            set_params_filter_input_search_of_blood_test_view(from_date, to_date) {
                this.filters.date.from = moment(from_date).format("YYYY-MM-DD")
                this.filters.date.to   = moment(to_date).format("YYYY-MM-DD")
            },  
            set_params_filter_input_search_of_chairs_view(from_date, to_date) {
                this.filters.date.from = moment(from_date).format("YYYY-MM-DD")
                this.filters.date.to   = moment(to_date).format("YYYY-MM-DD")
            },                      
            check_type_of_prospective() {
                
                // Recuperiamo la tipologia di prospettiva
                const {
                    p, // prospective
                    listPharma,
                    vm
                } = this.$route.query

                if(listPharma){
                    this.list_pharma = 1
                    this.view.mode = "pharmacy"                   
                } else if(vm == 1){
                    this.list_pharma = 0
                    this.view.mode = "standard"       
                } else if(vm == 0){
                    this.list_pharma = 0
                    this.view.mode = "calendar"       
                } else if(vm == 2){
                    this.list_pharma = 0
                    this.view.mode = "chairs"       
                } else if(vm == 3){
                    this.list_pharma = 0
                    this.view.mode = "blood_tests"       
                }  

                // Se p è presente ed è vero che è uguale a "s" o "c"
                if(p && (p == "s" || p == "c" || p == "f" || p == "h" || p == "b")) {

                    // Se la prospettiva è s (standard) cambia la view
                    if(p == "s") {

                        this.view.mode = "standard"
                        
                        // Aggiorniamo i dati del grafico
                        this.pushDataChart()

                    } else if(p == "f") {
                        this.list_pharma = 1
                        this.view.mode = "pharmacy"
                    } else if(p == "h") {
                        this.list_pharma = 0
                        this.view.mode = "chairs"

                    } else if(p == "b") {
                        this.list_pharma = 0
                        this.view.mode = "blood_tests"

                    }
                    
                }
                else {
                    // Se non è presente niente o il dato è indefinito impostiamo nell'url la prospettiva di tipo calendario
                    this.$router.replace({
                        name: "user_appointments_index",
                        query: {...this.$route.query, p: "c"}
                    }).catch(()=>{});

                }

            },
            time_difference_from_time(p_h_start, with_type, only_minutes, p_h_end) {

                const h_start = moment(
                    moment(p_h_start).format(`YYYY-MM-DD HH:mm`)
                )

                const h_end = p_h_end ? moment(moment(p_h_end).format(`YYYY-MM-DD HH:mm`)) : moment(moment().format(`YYYY-MM-DD HH:mm`))

                const duration = moment.duration(
                    h_end.diff(h_start)
                )

                const attesa_minuti = duration.asMinutes()
                // Se i minuti sono oltre il 60 e non sono richiesti i soli minuti

                if(attesa_minuti >= 60 && !only_minutes) {
                    return with_type ? `> ${parseInt(duration.asHours())} h.` : parseInt(duration.asHours())
                }
                else {
                    return with_type ? `${attesa_minuti} m.` : attesa_minuti
                }
                

            },
            get_appointments_from_paginations(pages) {
                
                let appointments = []

                for(const page of pages) {
                    for(const appointment of page.appointments) {
                        appointments.push(appointment)
                    }
                }

                return appointments

            },
            async readRoom(roomid) {
                
                try {

                    const room = await this.axios.get(`user/rooms/read/detailId/${roomid}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.room.DB = room.data.availibilities  
                }
                catch(e) {

                    /*this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })*/

                    this.room.DB = []

                }

            },  
            async readAppointmentsByBloodTest(from_date, to_date) {
                this.loaders.reading.appointments_blood_test = true

                let currentDate = new Date().toJSON().slice(0, 10);
                let currentDate1 = currentDate + ' 00:00:00';
                let currentDate2 = currentDate + ' 23:59:59';

                let time_from = ' 00:00';
                let time_to = ' 00:00';

                const {
                    p, // prospective
                } = this.$route.query    
                
            
                
                if(p == "b" && document.getElementById("filter_from_date") != null){
                    let inputElem = document.getElementById('filter_from_date').value + ' 00:00:00';
                    if(inputElem != currentDate1){
                        currentDate1 = inputElem
                    }
                    let inputElem2 = document.getElementById('filter_to_date').value + ' 23:59:59';
                    if(inputElem2 != currentDate2){
                        currentDate2 = inputElem2
                    }                   
                }

                // Log the new value after an input is made
                // Impostiamo i valori nei campi input per la standard view
                this.set_params_filter_input_search_of_blood_test_view(currentDate1, currentDate2)

                let where2 = []

                let objWhere2 = {};
               

                if(p == "b" && document.getElementById("filter_from_date") != null){

                    if(document.getElementById("filter_from_date").value == document.getElementById("filter_to_date").value){
                        time_from = ' 00:00';
                        time_to = ' 23:59';
                    }
                    from_date = document.getElementById("filter_from_date").value + time_from;
                    to_date = document.getElementById("filter_to_date").value + time_to;

                }

                objWhere2["from_date"] = new Date(from_date);
                objWhere2["to_date"] = new Date(to_date);          

                where2.push(objWhere2); 
                
                try {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const yearUrl = urlParams.get('d'); 
                    if(yearUrl){
                        const getYear = yearUrl.split('-');
                        const year = getYear[0]; 
                        this.yearCal = year;
                    }
                    //lista appuntamenti
                    const appointments = await this.axios.post(`user/appointments/read/search/appointments/blood_tests`, {where2}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    }) 

                    this.DB.appointments_blood_tests = appointments.data
                    // Controlla la tipologia di prospettiva
                    this.check_type_of_prospective()

                }
                catch(e) {

                    this.DB.appointments_blood_tests = []

                }
                finally {
                    this.loaders.reading.appointments_blood_test = false
                }

            },                   
            async readAppointmentsByChairs(from_date, to_date) {
                this.loaders.reading.appointments_chairs = true

                let currentDate = new Date().toJSON().slice(0, 10);
                let currentDate1 = currentDate + ' 00:00:00';
                let currentDate2 = currentDate + ' 23:59:59';

                let time_from = ' 00:00';
                let time_to = ' 00:00';

                const {
                    p, // prospective
                } = this.$route.query    
                
            
                
                if(p == "h" && document.getElementById("filter_from_date") != null){
                    let inputElem = document.getElementById('filter_from_date').value + ' 00:00:00';
                    if(inputElem != currentDate1){
                        currentDate1 = inputElem
                    }
                    let inputElem2 = document.getElementById('filter_to_date').value + ' 23:59:59';
                    if(inputElem2 != currentDate2){
                        currentDate2 = inputElem2
                    }                   
                }

                // Log the new value after an input is made
                // Impostiamo i valori nei campi input per la standard view
                this.set_params_filter_input_search_of_standard_view(currentDate1, currentDate2)

                let where2 = []

                let objWhere2 = {};
               

                if(p == "h" && document.getElementById("filter_from_date") != null){

                    if(document.getElementById("filter_from_date").value == document.getElementById("filter_to_date").value){
                        time_from = ' 00:00';
                        time_to = ' 23:59';
                    }
                    from_date = document.getElementById("filter_from_date").value + time_from;
                    to_date = document.getElementById("filter_to_date").value + time_to;

                }

                objWhere2["from_date"] = new Date(from_date);
                objWhere2["to_date"] = new Date(to_date);          

                where2.push(objWhere2); 
                
                try {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const yearUrl = urlParams.get('d'); 
                    if(yearUrl){
                        const getYear = yearUrl.split('-');
                        const year = getYear[0]; 
                        this.yearCal = year;
                    }
                    //lista appuntamenti
                    const appointments = await this.axios.post(`user/appointments/read/search/appointments/chairs`, {where2}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    }) 

                    this.DB.appointments_chairs        = appointments.data
                    
                    // Controlla la tipologia di prospettiva
                    this.check_type_of_prospective()
                    


                }
                catch(e) {

                    this.DB.appointments_chairs        = []

                }
                finally {
                    this.loaders.reading.appointments_chairs = false
                }

            },                         
            async readAppointments(from_date, to_date) {
                this.loaders.reading.appointments = true

                let currentDate = new Date().toJSON().slice(0, 10);
                let currentDate1 = currentDate + ' 00:00:00';
                let currentDate2 = currentDate + ' 23:59:59';

                let time_from = ' 00:00';
                let time_to = ' 00:00';

                const {
                    p, // prospective
                } = this.$route.query    
                
            
                
                if(p == "s" && document.getElementById("filter_from_date") != null){
                    let inputElem = document.getElementById('filter_from_date').value + ' 00:00:00';
                    if(inputElem != currentDate1){
                        currentDate1 = inputElem
                    }
                    let inputElem2 = document.getElementById('filter_to_date').value + ' 23:59:59';
                    if(inputElem2 != currentDate2){
                        currentDate2 = inputElem2
                    }                   
                }

                // Log the new value after an input is made
                // Impostiamo i valori nei campi input per la standard view
                this.set_params_filter_input_search_of_chairs_view(currentDate1, currentDate2)

                // Instanziamo
                let where = {
                    AND: []
                }
                let where2 = []

                let objWhere2 = {};
               

                if(p == "s" && document.getElementById("filter_from_date") != null){

                    if(document.getElementById("filter_from_date").value == document.getElementById("filter_to_date").value){
                        time_from = ' 00:00';
                        time_to = ' 23:59';
                    }
                    from_date = document.getElementById("filter_from_date").value + time_from;
                    to_date = document.getElementById("filter_to_date").value + time_to;

                }

                // Impostiamo i valori proveniente da from_date e to_date

                where.AND.push({
                    OR: [{
                        date: {
                            gte: new Date(from_date),
                            lte: new Date(to_date)
                        }
                    }]
                })

                objWhere2["from_date"] = new Date(from_date);
                objWhere2["to_date"] = new Date(to_date);

                // Ricava filtri nell'URL ed impostali se presenti
                for(const filter of this.getFilterUrlPresent("r")) {
                    this.filters.rooms[filter] = true
                }

                // Controlla se ci sono dei filtri attivi
                if(this.checkFilterPresent()) {

                    let OR = []

                    // Se sono presenti filtri per le rooms
                    if(Object.keys(this.filters.rooms).length > 0 ) {

                        OR = []

                        let arrayRooms = [];

                        for(const id_room in this.filters.rooms) {
                            if(this.filters.rooms[id_room]) {
                                OR.push({
                                    id_room: parseInt(id_room)
                                })
                                arrayRooms.push(parseInt(id_room));
                                
                            }
                        }
                        objWhere2["id_room"] = arrayRooms;   
                        where.AND.push({OR})


                    }
                    if(Object.keys(this.filters.pathologies).length > 0 ) {

                        OR = []

                        let arrayPathology = [];

                        for(const id_pathology in this.filters.pathologies) {
                            if(this.filters.pathologies[id_pathology]) {
                                OR.push({
                                    patient_pathology: {
                                        id_pathology: parseInt(id_pathology)
                                    }
                                })
                                arrayPathology.push(parseInt(id_pathology));
                                   
                            }
                        }

                        objWhere2["id_pathology"] = arrayPathology;   

                        where.AND.push({OR})


                    }
                    if(Object.keys(this.filters.therapies).length > 0 ) {

                        OR = []

                        let arrayTherapies = [];

                        for(const id_therapy in this.filters.therapies) {
                            if(this.filters.therapies[id_therapy]) {
                                OR.push({
                                    patient_pathology: {
                                        id_therapy: parseInt(id_therapy)
                                    }
                                })
                                arrayTherapies.push(parseInt(id_therapy));
                                
                            }
                        }

                        objWhere2["id_therapy"] = arrayTherapies;

                        where.AND.push({OR})
   
                    }
                }

                where2.push(objWhere2); 

                const therapies_today = this.filters.therapies_today;
                
                try {

                    //lista appuntamenti
                    const appointments = await this.axios.post(`user/appointments/read/search/appointments`, {where, where2, therapies_today}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })   
                    

                    this.DB.appointments        = appointments.data


                    this.calendar.config.events = this.formatAppointmentForVuecal(appointments.data)

                    // Controlla la tipologia di prospettiva
                    this.check_type_of_prospective()
                    


                }
                catch(e) {

                    this.DB.appointments        = []
                    this.calendar.config.events = this.formatAppointmentForVuecal([])

                    /*this.$toast.open({
                        message: 'e.response.data.msg',
                        type:   `error`
                    })*/

                }
                finally {
                    this.loaders.reading.appointments = false
                }

            },
            changeModeView() {
                this.list_pharma = 0
                
                // Se non è presente una specifica richiesta di prospettiva effettua lo switch
                if(this.view.mode == "calendar") {
                    const currentDate = new Date().toJSON().slice(0, 10);
                    // Lettura appuntamenti impostando le date in giornata
                    this.readAppointments(
                        moment(currentDate).format("YYYY-MM-DD 00:00"),
                        moment(currentDate).format("YYYY-MM-DD 23:59")
                    );               
                    //this.view.mode = "standard"
                    
                    this.$router.replace({
                        name: "user_appointments_index",
                        query: {...this.$route.query, p: "c"}
                    }).catch(()=>{});


                } else if(this.view.mode == "pharmacy") {

                    this.list_pharma = 1    
                    
                    this.$router.replace({
                        name: "user_appointments_index",
                        query: {...this.$route.query, p: "f"}
                    }).catch(()=>{});                    

                }  else if(this.view.mode == "chairs") {
                    this.readAppointmentsByChairs(
                        moment().format("YYYY-MM-DD 00:00"),
                        moment().format("YYYY-MM-DD 23:59")
                    );   
                    this.$router.replace({
                        name: "user_appointments_index",
                        query: {...this.$route.query, p: "h"}
                    }).catch(()=>{});                    

                }  else if(this.view.mode == "blood_tests") {
                    this.readAppointmentsByBloodTest(
                        moment().format("YYYY-MM-DD 00:00"),
                        moment().format("YYYY-MM-DD 23:59")
                    );   
                    this.$router.replace({
                        name: "user_appointments_index",
                        query: {...this.$route.query, p: "b"}
                    }).catch(()=>{});                    

                } else {

                    //this.view.mode = "calendar"

                    this.$router.replace({
                        name: "user_appointments_index",
                        query: {...this.$route.query, p: "s"}
                    }).catch(()=>{});

                    this.pushDataChart()                    

                }

            },
            changeModeView2() {
                this.list_pharma = 0
                this.view.mode = "standard"
            },                        
            formatAppointmentForVuecal(appointments) {
                let appointments_for_vuecal = []
               
                for(const appointment of appointments) {
                    
                    var color = appointment.rooms.color
                    //var color2 = '#CE0000';
                    var textBox = '';
                    var lampeggiante = '';
                    var therapyName = '-' 
                    var svgIcon = '';

                    if(appointment.patient_pathology.therapy){
                        therapyName = appointment.patient_pathology.therapy.title 
                    } else {
                        therapyName = '-' 
                    }

                    if(appointment.appointments_day_after){
                        for(const appointment_day_after of appointment.appointments_day_after) {
                            
                            if(appointment_day_after && appointment_day_after.urgent && appointment_day_after.id_chair && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                color = '#CE0000'
                                lampeggiante = 'effetto-lampeggia-loop';
                                textBox = 'Paziente in terapia URGENTE - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                            } 

                            if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.patient_room_therapy && appointment_day_after.room_therapy_called && appointment_day_after.therapy_start_time && !appointment_day_after.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = '';
                                textBox = 'Paziente in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                            } 

                            if(appointment_day_after.id_chair == 0 && appointment_day_after.room_presence && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = 'effetto-lampeggia-loop';
                                textBox = 'In attesa di assegnazione poltrona'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                            }
                                                
                            if(appointment_day_after && !appointment_day_after.id_chair && appointment_day_after.room_presence && appointment_day_after.patient_room_therapy && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = 'effetto-lampeggia-loop';
                                textBox = 'Terapia: assegnare poltrona'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                            } 
                            
                            if(appointment_day_after && !appointment_day_after.id_chair && appointment_day_after.room_presence && !appointment_day_after.patient_room_therapy && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = 'effetto-lampeggia-loop';
                                textBox = 'Poltrona non assegnata'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                            }                      
                            
                            if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.patient_room_therapy && (appointment_day_after.prepared_pharma_start_time || appointment_day_after.medicine_ready_start_time) &&  !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = '';
                                textBox = 'Terapia: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                            }   
                            
                            if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.totem_presence && !appointment_day_after.room_presence && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = 'effetto-lampeggia-loop';
                                textBox = 'Inviare in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                            } 
                            
                            if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.patient_room_therapy && appointment_day_after.room_therapy_called && appointment_day_after.therapy_start_time && appointment_day_after.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                lampeggiante = ''
                                //color2 = '#008000'
                                textBox = 'Terapia terminata: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                            }   
                            
                            if(appointment_day_after && appointment_day_after.id_chair && ((!appointment_day_after.taking_charge_start_time && appointment_day_after.medicine_ready_start_time) || appointment_day_after.taking_charge_start_time && !appointment_day_after.medicine_ready_start_time) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = 'effetto-lampeggia-loop';
                                textBox = 'Terapia: assegnare farmaco'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                            }                      
                        
                            if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.patient_room_therapy  && (appointment_day_after.prepared_pharma_start_time || appointment_day_after.medicine_ready_start_time) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                //color2 = '#CE0000'
                                lampeggiante = '';
                                textBox = 'Terapia: farmaco e poltrona assegnati'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                            }  

                            if(appointment.totem_presence && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && moment(appointment.date).format('YYYYMMDD') != moment(appointment_day_after.date).format('YYYYMMDD')){
                                lampeggiante = '';
                                textBox = 'Visita medica: il paziente è nella sala di attesa'
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split m-auto" viewBox="0 0 16 16"><path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/></svg>'
                            }

                            //nella stanza per la visita
                            if(appointment.totem_presence && appointment.room_presence && !appointment.patient_room_therapy && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && moment(appointment.date).format('YYYYMMDD') != moment(appointment_day_after.date).format('YYYYMMDD')){
                                lampeggiante = '';
                                textBox = 'Visita medica: il medico lo sta visitando'                       
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/></svg>  '
                            }

                            //Paziente in pausa ma non è tornato ancora
                            if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && moment(appointment.date).format('YYYYMMDD') != moment(appointment_day_after.date).format('YYYYMMDD')){
                                lampeggiante = '';
                                textBox = 'Visita medica: il paziente è in pausa'   
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/></svg>'
                            }
                            
                            //Paziente è tornato dalla pausa
                            if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && moment(appointment.date).format('YYYYMMDD') != moment(appointment_day_after.date).format('YYYYMMDD')){
                                lampeggiante = '';
                                textBox = 'Visita medica: il paziente è tornato dalla pausa'                           
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto effetto-lampeggia-loop" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/></svg>'
                            }

                            //Appuntamento chiuso
                            if(appointment.totem_presence && appointment.room_presence && appointment.appointment_closed && appointment.therapy_nurses_end_time || (appointment.appointment_closed && appointment.urgent) || (appointment.appointment_closed) && moment(appointment.date).format('YYYYMMDD') != moment(appointment_day_after.date).format('YYYYMMDD')){
                                lampeggiante = '';
                                textBox = 'Visita medica completata'                           
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                            }

                            //Paziente non presentato 
                            if(!moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && !appointment.appointment_closed && moment(appointment.date).format('YYYYMMDD') != moment(appointment_day_after.date).format('YYYYMMDD')){
                                lampeggiante = '';
                                textBox = ''                         
                                svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-patch-exclamation m-auto" viewBox="0 0 16 16"><path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z"/><path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/></svg>'
                            }
                        
                          

                        }                            

                        if(this.filters.therapies_today && appointment.outside == 0){
                            for(const appointment_day_after of appointment.appointments_day_after) {
                                if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.patient_room_therapy && appointment_day_after.room_therapy_called && appointment_day_after.therapy_start_time && !appointment_day_after.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Paziente in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                                } 

                                if(appointment_day_after.id_chair == 0 && appointment_day_after.room_presence && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'In attesa di assegnazione poltrona'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                }
                                                    
                                if(appointment_day_after && !appointment_day_after.id_chair && appointment_day_after.room_presence && appointment_day_after.patient_room_therapy && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Terapia: assegnare poltrona'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                } 
                                
                                if(appointment_day_after && !appointment_day_after.id_chair && appointment_day_after.room_presence && !appointment_day_after.patient_room_therapy && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Poltrona non assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                }                      
                                
                                if(appointment_day_after && appointment_day_after.id_chair && !appointment_day_after.totem_presence  && appointment_day_after.patient_room_therapy && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Terapia: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                }   
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment_day_after.totem_presence && !appointment_day_after.room_presence && !appointment_day_after.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Inviare in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                                } 
                                
                                if(appointment_day_after && appointment_day_after.id_chair && !appointment_day_after.totem_presence && appointment_day_after.patient_room_therapy && appointment_day_after.room_therapy_called && appointment_day_after.therapy_start_time && appointment_day_after.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = ''
                                    //color2 = '#008000'
                                    textBox = 'Terapia terminata: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                }   
                                
                                if(appointment_day_after && appointment_day_after.id_chair && !appointment_day_after.totem_presence  && ((!appointment_day_after.taking_charge_start_time && appointment_day_after.medicine_ready_start_time || appointment_day_after.taking_charge_start_time && !appointment_day_after.medicine_ready_start_time)) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Terapia: assegnare farmaco'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                                }                      
                            
                                if(appointment_day_after && appointment_day_after.id_chair && !appointment_day_after.totem_presence && (appointment_day_after.prepared_pharma_start_time || appointment_day_after.medicine_ready_start_time) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Terapia: farmaco e poltrona assegnati'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                }  

                            }
                        } else if(this.filters.therapies_today && appointment.outside == 1){
                            for(const appointment_day_after of appointment.appointments_day_after) {
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.patient_room_therapy && appointment.room_therapy_called && appointment.therapy_start_time && !appointment.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Paziente in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                                } 

                                if(appointment_day_after.id_chair == 0 && appointment.room_presence && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'In attesa di assegnazione poltrona'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                }
                                                    
                                if(appointment_day_after && !appointment_day_after.id_chair && appointment.room_presence && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Terapia: assegnare poltrona'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                } 
                                
                                if(appointment_day_after && !appointment_day_after.id_chair && appointment.room_presence && !appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Poltrona non assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                }                      
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Terapia: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                }   
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.totem_presence && !appointment.room_presence && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Inviare in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                                } 
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.patient_room_therapy && appointment.room_therapy_called && appointment.therapy_start_time && appointment.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = ''
                                    //color2 = '#008000'
                                    textBox = 'Terapia terminata: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                }   
                                
                                if(appointment_day_after && appointment_day_after.id_chair && ((!appointment.taking_charge_start_time && appointment.medicine_ready_start_time || appointment.taking_charge_start_time && !appointment.medicine_ready_start_time)) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Terapia: assegnare farmaco'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                                }                      
                            
                                if(appointment_day_after && appointment_day_after.id_chair && (appointment.prepared_pharma_start_time || appointment.medicine_ready_start_time) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Terapia: farmaco e poltrona assegnati'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                } 
                                
                                if(appointment.totem_presence && !appointment.room_presence && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il paziente è nella sala di attesa'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split m-auto" viewBox="0 0 16 16"><path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/></svg>'
                                }

                                //nella stanza per la visita
                                if(appointment.totem_presence && appointment.room_presence && !appointment.patient_room_therapy && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il medico lo sta visitando'                       
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/></svg>  '
                                }

                                //Paziente in pausa ma non è tornato ancora
                                if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il paziente è in pausa'   
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/></svg>'
                                }
                                
                                //Paziente è tornato dalla pausa
                                if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il paziente è tornato dalla pausa'                           
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto effetto-lampeggia-loop" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/></svg>'
                                }

                                //Appuntamento chiuso
                                if(appointment.totem_presence && appointment.room_presence && appointment.appointment_closed && appointment.therapy_nurses_end_time || (appointment.appointment_closed && appointment.urgent) || (appointment.appointment_closed)){
                                    lampeggiante = '';
                                    textBox = 'Visita medica completata'                           
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                }

                                //Paziente non presentato 
                                if(!moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && !appointment.appointment_closed){
                                    lampeggiante = '';
                                    textBox = ''                         
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-patch-exclamation m-auto" viewBox="0 0 16 16"><path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z"/><path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/></svg>'
                                }
                        


                                if(!appointment.appointments_day_after && appointment.room_presence && appointment.appointment_closed && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Inviare in terapia'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'

                                } 
                    
                                
                                if(!appointment.appointments_day_after && !appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && !appointment.sent_verify && !appointment.taking_charge_start_time && !appointment.medicine_ready_start_time && !appointment.preparation_pharma && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Paziente dagli infermieri'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6 .3 5.3 .3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V136c0-2.7 .1-5.4 .3-8H96zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80V160H144v16zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6zM208 48V64H192c-4.4 0-8 3.6-8 8V88c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8H240V48c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8z"/></svg>'
                                }                

                                if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && appointment.sent_verify && !appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    textBox = 'Verifica terapia'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 46.3 14.3 32 32 32H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V266.8c-20.2 28.6-32 63.5-32 101.2c0 25.2 5.3 49.1 14.8 70.8C189.5 463.7 160.6 480 128 480c-53 0-96-43-96-96V96C14.3 96 0 81.7 0 64zM96 96v96h64V96H96zM224 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L352 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"/></svg>'
                                }  
                                
                                if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Presa in carico'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                                }                      
                                                    

                                if(!appointment.patient_room_therapy && appointment.taking_charge_start_time && appointment.preparation_pharma_start_time && !appointment.prepared_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#ff9900'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Preparazione farmaco'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto"  viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>'
                                } 
                            }     
                        } else if(!this.filters.therapies_today && appointment.outside == 1){
                            for(const appointment_day_after of appointment.appointments_day_after) {
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.patient_room_therapy && appointment.room_therapy_called && appointment.therapy_start_time && !appointment.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Paziente in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                                } 

                                if(appointment_day_after.id_chair == 0 && appointment.room_presence && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'In attesa di assegnazione poltrona'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                }
                                                    
                                if(appointment_day_after && !appointment_day_after.id_chair && appointment.room_presence && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Terapia: assegnare poltrona'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                } 
                                
                                if(appointment_day_after && !appointment_day_after.id_chair && appointment.room_presence && !appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Poltrona non assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/></svg>'
                                }                      
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Terapia: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                }   
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.totem_presence && !appointment.room_presence && !appointment.room_therapy_called && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Inviare in terapia - ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number + ' assegnata'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'                 
                                } 
                                
                                if(appointment_day_after && appointment_day_after.id_chair && appointment.patient_room_therapy && appointment.room_therapy_called && appointment.therapy_start_time && appointment.therapy_end_time && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = ''
                                    //color2 = '#008000'
                                    textBox = 'Terapia terminata: ' + appointment_day_after.chairs.title + ' N. ' + appointment_day_after.chairs.number
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                }   
                                
                                if(appointment_day_after && appointment_day_after.id_chair && ((!appointment.taking_charge_start_time && appointment.medicine_ready_start_time || appointment.taking_charge_start_time && !appointment.medicine_ready_start_time)) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Terapia: assegnare farmaco'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                                }                      
                            
                                if(appointment_day_after && appointment_day_after.id_chair && (appointment.prepared_pharma_start_time || appointment.medicine_ready_start_time) && moment(appointment_day_after.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = '';
                                    textBox = 'Terapia: farmaco e poltrona assegnati'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                } 
                                
                                if(appointment.totem_presence && !appointment.room_presence && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il paziente è nella sala di attesa'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split m-auto" viewBox="0 0 16 16"><path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/></svg>'
                                }

                                //nella stanza per la visita
                                if(appointment.totem_presence && appointment.room_presence && !appointment.patient_room_therapy && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il medico lo sta visitando'                       
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/></svg>  '
                                }

                                //Paziente in pausa ma non è tornato ancora
                                if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il paziente è in pausa'   
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/></svg>'
                                }
                                
                                //Paziente è tornato dalla pausa
                                if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    lampeggiante = '';
                                    textBox = 'Visita medica: il paziente è tornato dalla pausa'                           
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto effetto-lampeggia-loop" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/></svg>'
                                }

                                //Appuntamento chiuso
                                if(appointment.totem_presence && appointment.room_presence && appointment.appointment_closed && appointment.therapy_nurses_end_time || (appointment.appointment_closed && appointment.urgent) || (appointment.appointment_closed)){
                                    lampeggiante = '';
                                    textBox = 'Visita medica completata'                           
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                                }

                                //Paziente non presentato 
                                if(!moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && !appointment.appointment_closed){
                                    lampeggiante = '';
                                    textBox = ''                         
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-patch-exclamation m-auto" viewBox="0 0 16 16"><path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z"/><path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/></svg>'
                                }
                        


                                if(!appointment.appointments_day_after && appointment.room_presence && appointment.appointment_closed && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Inviare in terapia'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'

                                } 
                    
                                
                                if(!appointment.appointments_day_after && !appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && !appointment.sent_verify && !appointment.taking_charge_start_time && !appointment.medicine_ready_start_time && !appointment.preparation_pharma && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Paziente dagli infermieri'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6 .3 5.3 .3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V136c0-2.7 .1-5.4 .3-8H96zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80V160H144v16zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6zM208 48V64H192c-4.4 0-8 3.6-8 8V88c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8H240V48c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8z"/></svg>'
                                }                

                                if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && appointment.sent_verify && !appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    textBox = 'Verifica terapia'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 46.3 14.3 32 32 32H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V266.8c-20.2 28.6-32 63.5-32 101.2c0 25.2 5.3 49.1 14.8 70.8C189.5 463.7 160.6 480 128 480c-53 0-96-43-96-96V96C14.3 96 0 81.7 0 64zM96 96v96h64V96H96zM224 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L352 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"/></svg>'
                                }  
                                
                                if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#CE0000'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Presa in carico'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                                }                      
                                                    

                                if(!appointment.patient_room_therapy && appointment.taking_charge_start_time && appointment.preparation_pharma_start_time && !appointment.prepared_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                                    //color2 = '#ff9900'
                                    lampeggiante = 'effetto-lampeggia-loop';
                                    textBox = 'Preparazione farmaco'
                                    svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto"  viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>'
                                }  
                            

                            }
                        }    
                    } else {
                        
                        //totem 
                        if(appointment.totem_presence && !appointment.room_presence && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            lampeggiante = '';
                            textBox = 'Visita medica: il paziente è nella sala di attesa'
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split m-auto" viewBox="0 0 16 16"><path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/></svg>'
                        }

                        //nella stanza per la visita
                        if(appointment.totem_presence && appointment.room_presence && !appointment.patient_room_therapy && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            lampeggiante = '';
                            textBox = 'Visita medica: il medico lo sta visitando'                       
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/></svg>  '
                        }

                        //Paziente in pausa ma non è tornato ancora
                        if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            lampeggiante = '';
                            textBox = 'Visita medica: il paziente è in pausa'   
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/></svg>'
                        }
                        
                        //Paziente è tornato dalla pausa
                        if(appointment.totem_presence && appointment.room_presence && appointment.patient_pause && appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            lampeggiante = '';
                            textBox = 'Visita medica: il paziente è tornato dalla pausa'                           
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto effetto-lampeggia-loop" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/></svg>'
                        }

                        //Appuntamento chiuso
                        if((appointment.totem_presence && appointment.room_presence && appointment.appointment_closed && appointment.therapy_nurses_end_time) || (appointment.appointment_closed && appointment.urgent) || (appointment.appointment_closed)){
                            lampeggiante = '';
                            textBox = 'Visita medica completata'                           
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>'
                        }

                        //Paziente non presentato 
                        if(!moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && !appointment.appointment_closed){
                            lampeggiante = '';
                            textBox = ''                         
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-patch-exclamation m-auto" viewBox="0 0 16 16"><path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z"/><path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/></svg>'
                        }
                  


                        if(appointment.room_presence && appointment.appointment_closed && appointment.patient_room_therapy && !appointment.room_therapy_called && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            //color2 = '#CE0000'
                            lampeggiante = 'effetto-lampeggia-loop';
                            textBox = 'Inviare in terapia'
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>'

                        } 
              
                        
                        if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && !appointment.sent_verify && !appointment.taking_charge_start_time && !appointment.medicine_ready_start_time && !appointment.preparation_pharma && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            //color2 = '#CE0000'
                            lampeggiante = 'effetto-lampeggia-loop';
                            textBox = 'Paziente dagli infermieri'
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6 .3 5.3 .3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V136c0-2.7 .1-5.4 .3-8H96zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80V160H144v16zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6zM208 48V64H192c-4.4 0-8 3.6-8 8V88c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8H240V48c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8z"/></svg>'
                        }                

                        if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && appointment.sent_verify && !appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            //color2 = '#CE0000'
                            textBox = 'Verifica terapia'
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 46.3 14.3 32 32 32H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V266.8c-20.2 28.6-32 63.5-32 101.2c0 25.2 5.3 49.1 14.8 70.8C189.5 463.7 160.6 480 128 480c-53 0-96-43-96-96V96C14.3 96 0 81.7 0 64zM96 96v96h64V96H96zM224 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L352 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"/></svg>'
                        }  
                        
                        if(!appointment.patient_room_therapy && appointment.sent_nurses && !appointment.nurses_end_time && appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            //color2 = '#CE0000'
                            lampeggiante = 'effetto-lampeggia-loop';
                            textBox = 'Presa in carico'
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>'
                        }                      
                                            

                        if(!appointment.patient_room_therapy && appointment.taking_charge_start_time && appointment.preparation_pharma_start_time && !appointment.prepared_pharma_start_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            //color2 = '#ff9900'
                            lampeggiante = 'effetto-lampeggia-loop';
                            textBox = 'Preparazione farmaco'
                            svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="m-auto"  viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>'
                        }  
                        
                    }                       

                    $(`#stile`).append(
                        `
                            <style>
                                .bg_${color.slice(1)} {background-color: ${color};border: solid ${color};border-width: 2px 0;color:#fff; padding-left:5px}
                            </style>
                        `
                    )

                    if(appointment.appointments_day_after && this.filters.therapies_today){
                        for(const appointment_day_after of appointment.appointments_day_after) {
                            appointments_for_vuecal.push({
                                start: `${moment(appointment_day_after.date).format('YYYY-MM-DD') + ' ' + moment(appointment_day_after.h_start).format('HH:mm')}`,
                                end: `${moment(appointment_day_after.date).format('YYYY-MM-DD') + ' ' + moment(appointment_day_after.h_end).format('HH:mm')}`,
                                
                                title:`
                                <span
                                    class= "f-size-1-em"
                                    title= "${moment(appointment_day_after.h_start).format('HH:mm') + " - " + moment(appointment_day_after.h_end).format('HH:mm')}\n ${appointment.totem_number ? appointment.totem_number : ''} ${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name}\nTerapia: ${therapyName}\n${textBox}">
                                    ${ moment(appointment_day_after.h_start).format('HH:mm') + " - " + moment(appointment_day_after.h_end).format('HH:mm')} - ${appointment.totem_number ? appointment.totem_number : ''}  ${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name} - Terapia:  <span class="font-weight-bold f-family-monospace">${therapyName}</span>
                                </span> 

                                <div class="float-right pr-2">${svgIcon}</div> 


                                </span>                                                                                                                                     
                                <div
                                    class= "f-size-1_2-em"
                                    title= "${ moment(appointment.h_start).format('HH:mm')} - ${moment(appointment.h_end).format('HH:mm')}\n${appointment.patient_pathology.patient.name} ${appointment.patient_pathology.patient.surname}\n${therapyName}">
                                    ${textBox} 
                                </div>                        
                                `,
                                class: `bg_${color.slice(1)} ${lampeggiante}`,
                                id:     appointment.id,
                                content: ``,
                                id_: appointment_day_after.id,
                                isTherapyAppointment: appointment.isTherapyAppointment
                            })
                        }    
                    } else {
                        appointments_for_vuecal.push({
                            start: `${moment(appointment.date).format('YYYY-MM-DD') + ' ' + moment(appointment.h_start).format('HH:mm')}`,
                            end: `${moment(appointment.date).format('YYYY-MM-DD') + ' ' + moment(appointment.h_end).format('HH:mm')}`,
                            
                            title:`
                            <span
                                class= "f-size-1-em"
                                title= "${moment(appointment.h_start).format('HH:mm') + " - " + moment(appointment.h_end).format('HH:mm') }\n ${appointment.totem_number ? appointment.totem_number : ''} ${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name}\nTerapia: ${therapyName}\n${textBox}">
                                ${moment(appointment.h_start).format('HH:mm') + " - " + moment(appointment.h_end).format('HH:mm')} - ${appointment.totem_number ? appointment.totem_number : ''}  ${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name} - Terapia:  <span class="font-weight-bold f-family-monospace">${therapyName}</span>
                            </span> 

                            <div class="float-right pr-2">${svgIcon}</div> 


                            </span>                                                                                                                                     
                            <div
                                class= "f-size-1_2-em"
                                title= "${ moment(appointment.h_start).format('HH:mm')} - ${moment(appointment.h_end).format('HH:mm')}\n${appointment.patient_pathology.patient.name} ${appointment.patient_pathology.patient.surname}\n${therapyName}">
                                ${textBox} 
                            </div>                        
                            `,
                            class: `bg_${color.slice(1)} ${lampeggiante}`,
                            id:     appointment.id,
                            content: ` `
                        })
                       
                    }

                }

                return appointments_for_vuecal
                
            },
            checkFilterPresent() {
                return (
                    Object.keys(this.filters.rooms).length > 0 ||
                    Object.keys(this.filters.pathologies).length > 0 ||
                    Object.keys(this.filters.therapies).length > 0 ||
                    Object.keys(this.filters.therapies_today).length > 0
                )
            },
            getFilterUrlPresent(param) {

                /*

                    * Leggenda filtri

                    * r = rooms/stanze

                */

                let res = this.$route.query[param]

                return res && res.length > 0 ? res.replace(/\s/g, '').split(",").filter(Number) : []

            },
            readIfSoftwareFunctions() {
                if(store.getters.isLogged && !store.getters.isAuthorized) {
                    this.$toast.open({
                        message: `Non hai ancora nessuna autorizzazione per l'utilizzo del software`,
                        type:    `error`
                    })
                }
            },

            async readRooms() {

                try {

                    const rooms = await this.axios.get(`user/rooms/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.rooms.DB = rooms.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },
            async readPathologies() {

                try {

                    const pathologies = await this.axios.get(`user/pathologies/read/actives/appointments`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.pathologies.DB = pathologies.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },
            async readTherapies() {

                try {

                    const therapies = await this.axios.get(`user/therapies/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.therapies.DB = therapies.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },

            readQuantityRoomSelected() {

                let quantity = 0

                for(let key in this.calendar.config.roomSelected) {
                    if(this.calendar.config.roomSelected[key]) quantity++
                }

                return quantity

            },
            updateDayOfAppointment(event) {
                if(!this.functionDisabled(10, "read")) {
                    if(this.filters.therapies_today == 1 && event.isTherapyAppointment){
                        this.$router.replace(`/user/agenda/modifica/${event.id}/${event.id_}`).catch(()=>{});
                    } else {
                        this.$router.replace(`/user/agenda/modifica/${event.id}`).catch(()=>{});
                    }
                }
                else {
                    this.$toast.open({
                        message: "Non sei abilitato a visualizzare il dettaglio dell'appuntamento",
                        type:   "error"
                    })
                }
            },
            updateDayOfAppointmentTherapies(event) {
                console.log(event)
                if(!this.functionDisabled(10, "read")) {
                    if(event.appointment.isTherapyAppointment || event.appointment.isBloodTestAppointment){
                        this.$router.replace(`/user/agenda/modifica/${event.appointment.id}/${event.id}`).catch(()=>{});
                    } else {
                        this.$router.replace(`/user/agenda/modifica/${event.appointment.id}`).catch(()=>{});
                    }
                }
                else {
                    this.$toast.open({
                        message: "Non sei abilitato a visualizzare il dettaglio dell'appuntamento",
                        type:   "error"
                    })
                }
            },           
            functionDisabled(id_function, action) {
                if(store.getters.softwareFunctions){
                    for(let sFunction of store.getters.softwareFunctions) {
                        if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                            return true
                        }
                    }
                }    
            },
            automaticFilters() {
                if(store.getters.pathologyCompetence) {
                    this.filters.pathologies[store.getters.pathologyCompetence.title] = true
                    this.configCalendar('pathologies', store.getters.pathologyCompetence.title, 'id_pathology', store.getters.pathologyCompetence.id)
                }
                else {
                    
                    // Levare seleziona di tutti  ifiltri
                    this.setAllFilters()
                    this.configCalendar(null, null, null, null, true)

                }
            },
            createPDF(ref) {
                this.$refs[ref].generatePdf()
            },
            pushDataChart() {
                this.charts.doughnut.data.datasets[0].data[0] = this.count_appointments_nessunostato
                this.charts.doughnut.data.datasets[0].data[1] = this.count_appointments_insaladiaspetto
                this.charts.doughnut.data.datasets[0].data[2] = this.count_appointments_invisita
                this.charts.doughnut.data.datasets[0].data[3] = this.count_appointments_visitacompletata
                this.charts.doughnut.data.datasets[0].data[4] = this.count_appointments_pausa
            },
            appointments_average_time(appointments) {

                let times = []

                for(const appointment of appointments) {

                    times.push({
                        visit: (
                            appointment.room_presence_arrival &&
                            appointment.appointment_closed
                        ) ? this.time_difference_from_time(
                            appointment.room_presence_arrival,
                            false,
                            true,
                            appointment.appointment_closed.date
                        )
                        : false,
                        waiting: (
                            appointment.totem_presence_arrival
                        ) ? this.time_difference_from_time(
                            appointment.h_start,
                            false,
                            true,
                            appointment.room_presence_arrival
                        ) : false
                    })

                }

                let total = {
                    waiting: 0,
                    visit: 0
                }

                for(const time of times) {
                    total.waiting += time.waiting
                    total.visit += time.visit
                }

                const get_length_within_false = key => {
                    
                    return (
                        times.filter(
                            time => time[key] != false
                        )
                    ).length

                }

                return {
                    waiting: `${(total.waiting/get_length_within_false("waiting")).toFixed(2)}`,
                    visit: `${(total.visit/get_length_within_false("visit")).toFixed(2)}`
                }

            },
            functionSpecialEnabled(id_function) {

                // Se sono presenti abilitazioni all'utilizzo delle funzioni software
                if(store.getters.softwareSpecialFunctions) {
                    //sFunction == software function
                    for(let sFunction of store.getters.softwareSpecialFunctions) {
                        // Se la funzione software corrisponde all'ID passato
                        if(sFunction.id_software_special_function == id_function && sFunction.abilitation) {
                            return true
                        }
                    }
                }

                return false

            },
            changeWeekDayShow() {
                if(this.calendar.flags.hideWeekDays) {
                    this.calendar.config.hideWeekDays = [6,7]
                    this.calendar.flags.hideWeekDays  = false
                }
                else {
                    this.calendar.config.hideWeekDays = []
                    this.calendar.flags.hideWeekDays  = true
                }
            },
            makeOrder(
                key
            ) {
                for(
                    const flag in this.ordinamento
                ) {
                    if(
                        flag != key
                    ) this.ordinamento[flag] = null
                    else {
                        if(
                            this.ordinamento[flag] == "asc"
                        ) this.ordinamento[flag] = "desc"
                        else this.ordinamento[flag] = "asc"
                    }
                }
            },
            async readAppointmentsToday(from_date, to_date) {
                const {
                    p, // prospective
                } = this.$route.query             

                this.readAppToday = 1;

                if(p == "s") {  
                    from_date = document.getElementById("filter_from_date").value + ' 00:00';
                    to_date = document.getElementById("filter_to_date").value + ' 23:59';
                }

                
                // Instanziamo
                let where = {
                    AND: []
                }

                // Impostiamo i valori proveniente da from_date e to_date

                where.AND.push({
                    date: {
                        gte: new Date(from_date),
                        lte: new Date(to_date)
                    }
                })
                

                try {

                    this.patient_waiting_nurses = 0 
                    this.patient_waiting_therapy = 0 
                    this.pharma_waiting_therapy = 0
                    this.patient_waiting_take_charge = 0 
                    this.patient_waiting_verify = 0  
                    this.patient_waiting_route = 0  
                 
                    const today = await this.axios.post(`user/appointments/read_all_today`, {where}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })                   
                    var currentDate3 = new Date();                 
                    var currentDateWithFormat = currentDate3.toJSON().slice(0,10).replace(/-/g,'/');    
                    var appointmentDate;
                    today.data.forEach((value) => {    
                        appointmentDate = new Date(value.h_start); 
                        appointmentDate = appointmentDate.toJSON().slice(0,10).replace(/-/g,'/'); 
          
                        if(!value.appointments_day_after && appointmentDate === currentDateWithFormat && value.prepared_pharma_start_time && !value.room_therapy_called){             
                            this.patient_waiting_therapy = 1                             
                        } 
                        if(appointmentDate === currentDateWithFormat && !value.patient_room_therapy && value.taking_charge_start_time && !value.prepared_pharma_start_time && value.preparation_pharma_start_time){
                            this.pharma_waiting_therapy = 1            
                        }                    
                        if(appointmentDate === currentDateWithFormat && !value.patient_room_therapy && value.sent_nurses && !value.taking_charge_start_time && !value.prepared_pharma_start_time && !value.preparation_pharma_start_time){
                            this.patient_waiting_nurses = 1            
                        } 
                        if(appointmentDate === currentDateWithFormat && !value.patient_room_therapy && value.sent_nurses && value.taking_charge_start_time && !value.prepared_pharma_start_time && !value.preparation_pharma_start_time){
                            this.patient_waiting_take_charge = 1            
                        } 
                        if(appointmentDate === currentDateWithFormat && !value.patient_room_therapy && value.sent_nurses && value.sent_verify && !value.taking_charge_start_time && !value.prepared_pharma_start_time && !value.preparation_pharma_start_time){
                            this.patient_waiting_verify = 1            
                        } 
                    });
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            } ,
            cancelAutoUpdate () {
                clearInterval(this.interval1);
                clearInterval(this.interval2);
            }                  

        },
        async mounted() {
            this.readIfSoftwareFunctions()
            await this.readRooms()
            await this.readPathologies()
            await this.readTherapies()

            this.readAppointmentsByChairs(
                moment().format("YYYY-MM-DD 00:00"),
                moment().format("YYYY-MM-DD 23:59")
            ); 

            this.readAppointmentsByBloodTest(
                moment().format("YYYY-MM-DD 00:00"),
                moment().format("YYYY-MM-DD 23:59")
            );                 
       
            //this.resetFilter()
            //this.automaticFilters()
            //this.readAppointments()         

        }, 
        beforeDestroy() {
            this.cancelAutoUpdate();
            //console.log(this);
            this.DB.appointments = [];
            this.pathologies.DB = [];
            this.therapies.DB = [];
            this.room.DB = [];
            this.rooms.DB = [];
        },           
        created() {
            if(this.functionSpecialEnabled(7) || this.functionSpecialEnabled(12) || this.functionSpecialEnabled(13) || this.functionSpecialEnabled(14) || this.functionSpecialEnabled(15)){


                this.calendar.config.activeView = "day"
                const currentDate = new Date().toJSON().slice(0, 10);
                // Lettura appuntamenti impostando le date in giornata
                this.interval1 = setInterval(() => this.readAppointmentsToday(
                    moment(currentDate).format("YYYY-MM-DD 00:00"),
                    moment(currentDate).format("YYYY-MM-DD 23:59")
                ), 60000);                  


                //this.interval1 = setInterval(() => this.readAppointmentsToday(), 60000);
                this.interval2 = setInterval(() => this.search_appointments_by_url_params(), 60000);
            }
        },              
        computed: {            
            getRoomAvailibilities() { 

                let year = this.yearCal
                let easter = this.easterDateLLongre(year);
                var myString = []
                var days_holiday = {
                    "0": year + "-01-01",
                    "1": year + "-01-06",
                    "2": year + "-04-25",
                    "3": year + "-05-01",
                    "4": year + "-06-02",
                    "5": year + "-06-29",
                    "6": year + "-08-15",
                    "7": year + "-11-01",
                    "8": year + "-12-08",
                    "9": year + "-12-25",
                    "10": year + "-12-26",
                    "11": easter 
                };  

                days_holiday = JSON.parse(JSON.stringify(days_holiday));
                myString = []
                availibilities = []                       
                Object.keys(days_holiday).forEach(key => {
                    days_holiday_obj = {
                        [days_holiday[key]] : {
                            'am':{'to':['20:00'],'from':['08:00']},'pm':{'to':['20:00'],'from':['08:00']}
                        }
                    } 
                    myString.push(days_holiday_obj);                        
                }); 
                availibilities.push(
                    this.format_room_for_calendar_holidays(myString)
                ) 
            
                if(this.room.DB.length !== 0){  
                    var room = JSON.parse(JSON.stringify(this.room.DB));
                    for(const roomAv of room) {   
                        availibilities.push(
                            this.format_room_for_calendar(roomAv)
                        )                        
                    }     
                }

                newObj = availibilities.reduce((a, b) => Object.assign(a, b), {}) 
                return newObj;   

            },                  
            orderedTherapies() {
                return orderBy(this.therapies.DB, 'title')
            },
            orderedRooms() {
                return orderBy(this.rooms.DB, 'title')
            },
            orderedPathologies() {
                return filter(
                    orderBy(
                        this.pathologies.DB,
                        'id_pathology'
                    ),
                    o => {
                        return (
                            o.title.toUpperCase().includes(
                                this.filters.pathology_input_search.toUpperCase()
                            )
                        )
                    }
                )
            },
            appointmentsDay() {

                let flag = 0
                
                for(let event of this.calendar.config.events) {
                    if(moment().format("YYYY-MM-DD") == moment(event.start).format("YYYY-MM-DD")) flag++
                }

                return flag

            },
            bloodTestsForBloodTestsView() {

                let bloodTests = {}

                const search_req_ordinement = () => {
                    
                    let chiavi = [

                    ]
                    let ordinamento = [

                    ]

                    for(const val in this.ordinamento) {

                        // Se il valore ha un elemento
                        if(
                            this.ordinamento[val]
                        ) {

                            // Inseriamo la chiave oggetto
                            chiavi.push(
                                val
                            )
                            // Inseriamo il tipo di ordinamento
                            ordinamento.push(
                                this.ordinamento[
                                    val
                                ]
                            )

                        }

                    }

                    return {
                        chiavi,
                        ordinamento
                    }

                }
                for(const appointment of this.DB.appointments_blood_tests) {

                console.log(appointment)
                    
                    if(appointment){
                        if(!bloodTests[appointment.blood_test_type.title]) bloodTests[appointment.blood_test_type.title] = {
                            appointments: new Array(),
                            title: appointment.blood_test_type.title      
                        }                      

                        if(!appointment.urgent && appointment.totem_presence && appointment.room_presence && appointment.patient_room_blood_test && appointment.room_blood_test_called && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'therapyStart'     
                        }  else if(!appointment.urgent && appointment.totem_presence && !appointment.room_presence && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'totem'
                        } else if(!appointment.urgent && !appointment.totem_presence && !appointment.room_presence && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'notShown' 
                        }  else if(appointment.urgent && !appointment.totem_presence && !appointment.room_presence && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'urgent' 
                        }                         

                        bloodTests[appointment.blood_test_type.title].appointments.push(appointment)

                    }

                }

                // Ordinamento degli appuntamenti in base alla selezione
                for(
                    const titolo in bloodTests
                ) {

                    const {
                        appointments
                    } = bloodTests[
                        titolo
                    ]

                    bloodTests[
                        titolo
                    ].appointments = orderBy(
                        appointments,
                        search_req_ordinement().chiavi,
                        search_req_ordinement().ordinamento
                    )

                }
                return bloodTests

            },             
            chairsForChairsView() {

                let chairs = {}

                const search_req_ordinement = () => {
                    
                    let chiavi = [

                    ]
                    let ordinamento = [

                    ]

                    for(const val in this.ordinamento) {

                        // Se il valore ha un elemento
                        if(
                            this.ordinamento[val]
                        ) {

                            // Inseriamo la chiave oggetto
                            chiavi.push(
                                val
                            )
                            // Inseriamo il tipo di ordinamento
                            ordinamento.push(
                                this.ordinamento[
                                    val
                                ]
                            )

                        }

                    }

                    return {
                        chiavi,
                        ordinamento
                    }

                }

                for(const appointment of this.DB.appointments_chairs) {
                    if(appointment.chairs){
                        if(!chairs[appointment.chairs.title + '_' + appointment.chairs.number]) chairs[appointment.chairs.title + '_' + appointment.chairs.number] = {
                            appointments: new Array(),
                            number: appointment.chairs.number,
                            title: appointment.chairs.title            
                        }                      

                        if(appointment.totem_presence && appointment.room_presence && appointment.patient_room_therapy && appointment.room_therapy_called && appointment.therapy_start_time && !appointment.therapy_end_time && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'therapyStart'     
                        } else if(!appointment.totem_presence && !appointment.room_presence && !appointment.prepared_pharma_start_time && !appointment.medicine_ready_start_time){
                            appointment['icon'] = 'preparation' 
                        } else if(appointment.totem_presence && !appointment.room_presence && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'totem'
                        } else if(appointment.totem_presence && appointment.room_presence && appointment.therapy_end_time){
                            appointment['icon'] = 'closed'
                        } else if(!appointment.totem_presence && !appointment.room_presence && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                            appointment['icon'] = 'notShown' 
                        }                       

                        chairs[appointment.chairs.title + '_' + appointment.chairs.number].appointments.push(appointment)

                    }

                }

                // Ordinamento degli appuntamenti in base alla selezione
                for(
                    const titolo in chairs
                ) {

                    const {
                        appointments
                    } = chairs[
                        titolo
                    ]

                    chairs[
                        titolo
                    ].appointments = orderBy(
                        appointments,
                        search_req_ordinement().chiavi,
                        search_req_ordinement().ordinamento
                    )

                }
                return chairs

            },           
            roomForPharma() {

                let appointments = [{rooms:[], chairs:[]}]
                let rooms = {}
                let chairs = {}

                const search_req_ordinement = () => {
                    
                    let chiavi = [

                    ]
                    let ordinamento = [

                    ]

                    for(const val in this.ordinamento) {

                        // Se il valore ha un elemento
                        if(
                            this.ordinamento[val]
                        ) {

                            // Inseriamo la chiave oggetto
                            chiavi.push(
                                val
                            )
                            // Inseriamo il tipo di ordinamento
                            ordinamento.push(
                                this.ordinamento[
                                    val
                                ]
                            )

                        }

                    }

                    return {
                        chiavi,
                        ordinamento
                    }

                }

                for(const appointment of this.DB.appointments_chairs) {
                    const dateNow = moment().format("YYYY-MM-DD")
                    const dateApp = moment(appointment.date).format('YYYY-MM-DD')

                    if(dateNow === dateApp && (appointment.preparation_pharma_start_time || appointment.prepared_pharma_start_time || appointment.id_sent_pharmacy)){
                        if(!chairs[appointment.chairs.title + '_' + appointment.chairs.number]) chairs[appointment.chairs.title + '_' + appointment.chairs.number] = {
                            appointments: new Array(),
                            number: appointment.chairs.number,
                            title: appointment.chairs.title            
                        }

                        chairs[appointment.chairs.title + '_' + appointment.chairs.number].appointments.push(appointment)
                    }
                                  
                } 
                
                for(const appointment of this.DB.appointments) {
                    const dateNow = moment().format("YYYY-MM-DD")
                    const dateApp = moment(appointment.date).format('YYYY-MM-DD')

                    if(dateNow === dateApp && (appointment.preparation_pharma_start_time || appointment.prepared_pharma_start_time || appointment.id_sent_pharmacy)){
                        if(!rooms[appointment.rooms.title]) rooms[appointment.rooms.title] = {
                            appointments: new Array(),
                            number: appointment.rooms.number,
                            title: appointment.rooms.title            
                        }

                        rooms[appointment.rooms.title].appointments.push(appointment)
                    }  
                    
                }                 

                // Ordinamento degli appuntamenti in base alla selezione
                for(
                    const titolo in chairs
                ) {

                    const {appointments} = chairs[titolo]

                    chairs[titolo].appointments = orderBy(
                        appointments,
                        search_req_ordinement().chiavi,
                        search_req_ordinement().ordinamento
                    )

                }

                for(
                    const titolo in rooms
                ) {

                    const {appointments} = rooms[titolo]

                    rooms[titolo].appointments = orderBy(
                        appointments,
                        search_req_ordinement().chiavi,
                        search_req_ordinement().ordinamento
                    )

                }               

                appointments[0].chairs.push(chairs)
                appointments[0].rooms.push(rooms)

                return appointments
            },

            roomForStadardView() {

                let rooms = {}

                const search_req_ordinement = () => {
                    
                    let chiavi = [

                    ]
                    let ordinamento = [

                    ]

                    for(const val in this.ordinamento) {

                        // Se il valore ha un elemento
                        if(
                            this.ordinamento[val]
                        ) {

                            // Inseriamo la chiave oggetto
                            chiavi.push(
                                val
                            )
                            // Inseriamo il tipo di ordinamento
                            ordinamento.push(
                                this.ordinamento[
                                    val
                                ]
                            )

                        }

                    }

                    return {
                        chiavi,
                        ordinamento
                    }

                }

                for(const appointment of this.DB.appointments) {

                    if(!rooms[appointment.rooms.title]) rooms[appointment.rooms.title] = {
                        appointments: new Array(),
                        number: appointment.rooms.title,
                        color: appointment.rooms.color                    
                    }

                    if(!appointment.appointments_day_after && appointment.totem_presence && appointment.room_presence && appointment.sent_nurses && !appointment.nurses_end_time && !appointment.taking_charge_start_time && !appointment.prepared_pharma_start_time && !appointment.patient_room_therapy && !appointment.patient_pause && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'nurse'
                    } else if((appointment.appointments_day_after && !appointment.id_chair && appointment.totem_presence && appointment.room_presence && !appointment.patient_pause && !appointment.id_nurse_closed_therapy && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')))){
                        appointment['icon'] = 'chairs'
                    } else if((!appointment.appointments_day_after && appointment.totem_presence && appointment.room_presence && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time && !appointment.medicine_ready_start_time && appointment.prepared_pharma_start_time && appointment.patient_room_therapy && !appointment.room_therapy_called && !appointment.patient_pause && !appointment.id_nurse_closed_therapy && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')))){
                        appointment['icon'] = 'therapyOn'
                    } else if((!appointment.appointments_day_after && appointment.totem_presence && appointment.room_presence && appointment.preparation_pharma_start_time && appointment.taking_charge_start_time && !appointment.medicine_ready_start_time && appointment.prepared_pharma_start_time && appointment.patient_room_therapy && appointment.room_therapy_called && !appointment.patient_pause && !appointment.id_nurse_closed_therapy && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')))){
                        appointment['icon'] = 'therapyStart'    
                    } else if((!appointment.appointments_day_after && appointment.totem_presence && appointment.room_presence && !appointment.preparation_pharma_start_time && !appointment.taking_charge_start_time && appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && appointment.patient_room_therapy && !appointment.room_therapy_called && !appointment.patient_pause && !appointment.id_nurse_closed_therapy && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')))){
                        appointment['icon'] = 'therapyOn'
                    } else if((!appointment.appointments_day_after && appointment.totem_presence && appointment.room_presence && !appointment.preparation_pharma_start_time && !appointment.taking_charge_start_time && appointment.medicine_ready_start_time && !appointment.prepared_pharma_start_time && appointment.patient_room_therapy && appointment.room_therapy_called && !appointment.patient_pause && !appointment.id_nurse_closed_therapy && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')))){
                        appointment['icon'] = 'therapyStart'                                              
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && appointment.room_presence && appointment.sent_nurses && !appointment.nurses_end_time && appointment.taking_charge_start_time && !appointment.preparation_pharma_start_time &&  !appointment.prepared_pharma_start_time && !appointment.patient_room_therapy && !appointment.patient_pause && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'takeCharge'
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && appointment.room_presence && appointment.sent_nurses && !appointment.nurses_end_time && appointment.taking_charge_start_time && appointment.preparation_pharma_start_time &&  !appointment.prepared_pharma_start_time && !appointment.patient_room_therapy && !appointment.patient_pause && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'preparation'
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && !appointment.room_presence && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'totem'
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && appointment.room_presence && !appointment.patient_room_therapy && !appointment.patient_pause && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'room'
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && appointment.room_presence && appointment.patient_pause && !appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'notBack'
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && appointment.room_presence && appointment.patient_pause && appointment.patient_pause_back && !appointment.appointment_closed && moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD'))){
                        appointment['icon'] = 'back'
                    } else if(((appointment.appointments_day_after || !appointment.appointments_day_after) && appointment.totem_presence && appointment.room_presence && appointment.appointment_closed || (appointment.appointment_closed && appointment.urgent)) || (appointment.id_nurse_closed_therapy && appointment.therapy_nurses_end_time) || (appointment.id_route_closed && appointment.route_end_time)){
                        appointment['icon'] = 'closed'
                    } else if((appointment.appointments_day_after || !appointment.appointments_day_after) && !moment(appointment.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && !appointment.appointment_closed){
                        appointment['icon'] = 'notShown'
                    }                       

                    rooms[appointment.rooms.title].appointments.push(appointment)

                }

                // Ordinamento degli appuntamenti in base alla selezione
                for(
                    const titolo in rooms
                ) {

                    const {
                        appointments
                    } = rooms[
                        titolo
                    ]

                    rooms[
                        titolo
                    ].appointments = orderBy(
                        appointments,
                        search_req_ordinement().chiavi,
                        search_req_ordinement().ordinamento
                    )

                }
                return rooms

            },
            roomsForPDF() {

                let rooms = {}

                for(const appointment of this.DB.appointments) {

                    if(!rooms[appointment.rooms.title]) rooms[appointment.rooms.title] = {
                        pages_appointments: [{
                            appointments: []
                        }]
                    }

                    // Ricaviamo quantità delle pagine
                    const length = rooms[appointment.rooms.title].pages_appointments.length
                    // Controlliamo nell'ultima pagina la quantità degli appuntamenti
                    const q_appuntamenti = rooms[appointment.rooms.title].pages_appointments[length-1].appointments.length
                    // Se la quantità è minore di 16 allora inserisci nuovo appuntamento
                    if(q_appuntamenti<16) {
                        rooms[appointment.rooms.title].pages_appointments[length-1].appointments.push(appointment)
                    }
                    // Altrimenti crei una nuova pagina
                    else {
                        rooms[appointment.rooms.title].pages_appointments.push({appointments: [appointment]})
                    }

                }

                return rooms

            },
            check_presence_of_wrong_url_params() {

                const {
                    v, // view
                    d  // day
                } = this.$route.query

                return !v || !d || v != "d" && v != "w" && v != "m" && v != "y" || !moment(d).isValid()

            },
            count_appointments_nessunostato() {

                const length = this.DB.appointments.filter(
                    appointment => !appointment.totem_presence
                ).length

                return length

            },
            count_appointments_insaladiaspetto() {
                return (
                    this.DB.appointments.filter(
                        appointment => appointment.totem_presence && !appointment.room_presence
                    ).length
                )
            },
            count_appointments_invisita() {
                return (
                    this.DB.appointments.filter(
                        appointment => appointment.totem_presence && appointment.room_presence && !appointment.appointment_closed && !appointment.patient_pause
                    ).length
                )
            },
            count_appointments_visitacompletata() {
                return (
                    this.DB.appointments.filter(
                        appointment => appointment.appointment_closed
                    ).length
                )
            },
            count_appointments_pausa() {
                return (
                    this.DB.appointments.filter(
                        appointment => appointment.patient_pause && !appointment.appointment_closed
                    ).length
                )
            },
            data_for_excel() {
                return this.DB.appointments.map(appointment => {
                    return {
                        INIZIO: moment(appointment.h_start).format("HH:mm")
                    }
                })
            }
        },     
        watch: {
            getRoomAvailibilities: {
                deep: true,
                handler: function (newVal) {           
                    if(Object.keys(newVal).length == 12){
                        this.classCal = 1
                        list = [];
                        list2 = {}; 
                        arr = new Array();  
                        objroom = new Array();                         
                        newVal = []
                        availibilities = [];
                        newObj = {};   
                        days_holiday_obj = {} ; 
                        listHoliday = [];
                        list2Holiday = {}; 
                    } else {
                        this.classCal = 2
                        list = [];
                        list2 = {}; 
                        arr = new Array();  
                        objroom = new Array();                        
                        newVal = null
                        availibilities = [];
                        newObj = {};  
                        days_holiday_obj = {} ;  
                        listHoliday = [];
                        list2Holiday = {}; 
                    }
                }
            },                       
            'filters.date.from'(new_value, old_value) {

                const null_or_after = (
                    !new_value ||
                    moment(new_value).isAfter(
                        moment(
                            this.filters.date.to
                        )
                    )
                )

                if(null_or_after) this.filters.date.from = old_value

            },
            'filters.date.to'(new_value, old_value) {

                const null_or_before = (
                    !new_value ||
                    moment(new_value).isBefore(
                        moment(
                            this.filters.date.from
                        )
                    )
                )

                if(null_or_before) this.filters.date.to = old_value

            }
        }

    }

</script>

<style>
    .red {
        background-color: #ff0000;
        border: solid #ff0000;
        border-width: 2px 0;
    }
</style>